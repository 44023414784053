import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Menu} from "./menu";
import { useDispatch, useSelector } from "react-redux";
import {DRAWER_WIDTH} from "../../constants";
import { appState, setMenuState } from "../../redux/reducers/appReducer";

const windowWidth = window?.innerWidth;
const isMobileDevice = windowWidth <= 768;

type MainLayout = {
  children?: any
}
export const MainLayout = ({ children }: MainLayout) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isMainMenuOpen } = useSelector(appState);

  const changeOpenMenu = () => {
    dispatch(setMenuState(!isMainMenuOpen));
  };
  return (
    <div className={classes.root}>
      <Menu changeOpenMenu={changeOpenMenu} />
      <main
        className={classes.content}
        style={{
          width: isMainMenuOpen
            ? isMobileDevice
              ? "100%"
              : `calc(100% - ${DRAWER_WIDTH}px`
            : "100%"
        }}
      >
        {children}
      </main>
    </div>
  );
};


export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  paper: {
    overflowX: "hidden"
  },
  menuButton: {
    padding: 5
  },
  drawerContent: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    position: "relative",
    overflowX: "hidden"
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: "width .1s linear"
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(8) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2),
    ...theme.mixins.toolbar,
    minHeight: "60px",
    [theme.breakpoints.down(576)]: {
      minHeight: "80px"
    }
  },
  content: {
    flexGrow: 1
  },
  toolbarOpen: {
    flexDirection: "row"
  },
  toolbarClose: {
    flexDirection: "column",
    padding: "20px 0 0 0"
  },
  listItemSolo: {
    gap: 20
  },
  listItem: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.04em",
    color: "#9194A9",
    textDecoration: "none",
    height: 45,
    "& li": {
      height: 45
    }
  },
  active: {
    color: "#1375D1"
  },
  itemIconCollapsed: { display: "flex", justifyContent: "center" },
  ml: { marginRight: "0" },
  mr: {
    marginRight: "-20px",
    [theme.breakpoints.down(600)]: {
      marginRight: 0
    }
  }
}));
