import { createTheme } from '@material-ui/core';
import typography from './typography';

const $primary = '#1375D1';
const $blue = '#2f50bb';
const $secondary = '#3d4977';
const $inheritDefault1 = '#fefefe';
const $inheritDefault2 = '#f8f9ff';
const $second = '#070919';
const $red = '#f83245';
const $orange = '#f4772e';
const $green = '#1bc943';

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: $primary,
    },
    grey: {
      300: $inheritDefault1,
      A100: $inheritDefault2,
    },
    secondary: {
      main: $secondary,
    },
    error: {
      main: $red,
    },
    success: {
      main: $green,
    },
    warning: {
      main: $orange,
    },
    helpers: {
      primary: $blue,
      main: 'rgba(25, 46, 91, .035)',
    },
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  shape: {
    borderRadius: '0.5rem',
  },
  overrides: {
    MuiButton: {
      text: {
        paddingLeft: '14px',
        paddingRight: '14px',
      },
      containedSizeSmall: {
        paddingLeft: '14px',
        paddingRight: '14px',
      },
      root: {
        textTransform: 'none',
        fontWeight: 'normal',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: $second,
        padding: '8px 16px',
        fontSize: '13px',
      },
      arrow: {
        color: $second,
      },
    },
  },
  typography,
});

export default MuiTheme;
