import React, {useMemo, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {InputWarning} from "../../form/warnings";
import {AutoSuggest} from "../../form/AutoSuggest";
import {CButton} from "../../buttons";
import styles from "./addMenu.module.scss";
import {Checkbox} from "@material-ui/core";
import clsx from "clsx";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";
import {DuplicatePermissionsProps} from "./types";


export const DuplicatePermissions = ({data}: DuplicatePermissionsProps) => {
  const {
    handleSubmit,
    control,
    formState: {errors}
  } = useForm();
  const {addCloseSnack} = useCustomSnackbar()
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState(null)
  const {onSubmit, list, type, targetName} = data;

  const onFormSubmit = async (body) => {
    if (!body.menu && !body.grant)
      return addCloseSnack('Выберите меню или права')

    setLoading(true)
    if (onSubmit)
      await onSubmit(body, selected)
    setLoading(false)
  }

  const name = useMemo(() => type === "user" ? "to_employee_id" : "to_group_id", [type])
  return <div>
    <h2>{`Дублировать права ${type === "user" ? "пользователя" : "роли"}`}</h2>
    <p>{`Дублируется: ${targetName || ''}`}</p>
    <form className={styles.menu__form} onSubmit={handleSubmit(onFormSubmit)}>
      <div className={styles.menu__form_item}>
        <Controller
          name={name}
          control={control}
          render={({field}) => {
            const {onChange, value} = field;
            return (
              <AutoSuggest
                withMargin
                list={list || []}
                showKey={type === "user" ? "fio" : "group_name"}
                valueKey={type === "user" ? "employee_id" : "group_id"}
                placeholder={"Дублировать для"}
                label={"Дублировать для"}
                value={value}
                onChange={(e, valueObj) => {
                  setSelected(valueObj)
                  onChange(e, value)
                }}
              />
            );
          }}
          rules={{required: true}}
          defaultValue={""}
        />

        {errors[name] && (
          <InputWarning>Это поле обязательно</InputWarning>
        )}
      </div>
      <div className={clsx(styles.menu__form_item, styles.menu__form_itemFlex)}>
        <div>
          <label htmlFor={"menu_check"}>Меню</label>
          <Controller
            name="menu"
            control={control}
            render={({field: {onChange, value}}) => (
              <Checkbox
                id={"menu_check"}
                checked={!!value}
                onChange={onChange}
              />
            )}
            defaultValue={false}
          />
        </div>
        <div>
          <label htmlFor={"rights_check"}>Права</label>
          <Controller
            name="grant"
            control={control}
            render={({field: {onChange, value}}) => (
              <Checkbox
                id={"rights_check"}
                checked={!!value}
                onChange={onChange}
              />
            )}
            defaultValue={false}
          />
        </div>
      </div>
      <div className={styles.menu__form_btn}>
        <CButton loading={loading} disabled={loading}>
          Сохранить
        </CButton>
      </div>
    </form>
  </div>
}