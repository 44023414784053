import React from "react";
import {Controller, useForm} from "react-hook-form";
import styles from "./addMenu.module.scss";
import {InputWarning} from "../../form/warnings";
import {CButton} from "../../buttons";
import {useSelector} from "react-redux";
import CustomInput from "../../form/CustomInput";
import {appState} from "../../../redux/reducers/appReducer";
import {AddGroupProps} from "./types";

export const AddGroup = ({data}: AddGroupProps) => {
  const {
    handleSubmit,
    control,
    formState: {errors}
  } = useForm();
  const {modalLoading} = useSelector(appState);
  const {onSubmit: onAddSubmit, body} = data;

  const onSubmit = async body => {
    await onAddSubmit(body);
  };
  return (
    <div className={styles.menu}>
      <h2>{`${body ? "Изменение" : "Добавление"}`} роли</h2>
      <form className={styles.menu__form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.menu__form_item}>
          <Controller
            name="group_name"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                label="Название"
                placeholder="Введите название"
                value={value}
                onChange={onChange}
                fullWidth
              />
            )}
            defaultValue={body?.group_name || ""}
            rules={{
              required: true
            }}
          />
          {errors.group_name && (
            <InputWarning>Это поле обязательно</InputWarning>
          )}
        </div>
        <div className={styles.menu__form_item}>
          <Controller
            name="group_code"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                label="Код"
                placeholder="Введите код"
                value={value}
                onChange={onChange}
                fullWidth
              />
            )}
            defaultValue={body?.group_code || ""}
            rules={{
              required: true
            }}
          />
          {errors.group_code && (
            <InputWarning>Это поле обязательно</InputWarning>
          )}
        </div>
        <div className={styles.menu__form_btn}>
          <CButton loading={modalLoading} disabled={modalLoading}>
            Сохранить
          </CButton>
        </div>
      </form>
    </div>
  );
};
