import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {NotFound} from "../../text";
import clsx from "clsx";
import {WrapperScrollbar} from "../../WrapperScrollbar";
import {LoaderData} from "../../LoaderData";

//@ts-ignore
const useStyles = makeStyles(() => ({
  headCell: {
    padding: "5px !important",
    borderLeft: "1px solid #F0F2F6",
    backgroundColor: "#ECEEFD !important",
    lineHeight: "14px"
  },
  table: {
    borderCollapse: "collapse"
  },
  headText: {
    color: "#696C7C",
    fontSize: 12,
    fontWeight: 600
  },
  tableContainer: {
    maxHeight: (props: {headerHeight?: number}) => !!props.headerHeight ? `calc(100vh - ${props.headerHeight}px)` : "calc(100vh - 200px)"
  },
  loaderWrapper: {
    padding: '5px 0'
  },
  container: {
    overflowY: "auto",
    overflowX: `auto !important`,
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    borderRadius: "0.5rem",
    width: "100%",
    color: "rgba(0, 0, 0, 0.87)",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: "#fff"
  },
  containerFooterHeight: {
    maxHeight: "calc(100vh - 230px) !important"
  },
  containerFooter: {
    borderRadius: "0.5rem",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
  },
  headRow: {
    backgroundColor: "#ECEEFD"
  },
  root: {
    marginBottom: 15
  },
  border: {
    border: "1px solid #F0F2F6",
    padding: "5px"
  },
  borderless: {
    padding: 0
  },
  cursor: {
    cursor: "pointer"
  },
  row: {
    "&:hover": {
      backgroundColor: "#ececec"
    }
  },
  classicDiv: {
    "&:nth-child(odd)": {
      backgroundColor: "#f9f9f9",
      "&:hover": {
        backgroundColor: "#ececec"
      }
    }
  },
  alternativeDiv: {
    backgroundColor: "#ffffff",
    "&:nth-child(4n - 1)": {
      backgroundColor: "#f9f9f9",
      "&:hover": {
        backgroundColor: "#ececec"
      }
    }
  },
  footer: {
    border: "1px solid #F0F2F6",
    padding: "5px",
    borderRadius: "0 0 0.5rem 0.5rem"
  },
  tableFooter: {
    borderRadius: "0.5rem 0.5rem 0 0",
    boxShadow: "none"
  },
  fixedRow: {
    position: "sticky",
    backgroundColor: "#ECEEFD !important",
    "&:hover": {
      backgroundColor: "#ECEEFD !important"
    }
  },
  fixedCell: {
    fontWeight: "700 !important"
  },
  highlightRow: {
    borderBottom: "2px solid #ccc !important"
  },
  stickyHeader: {
    top: "45px"
  }
}));

type CustomTableType = {
  headItems: any[],
  isEmpty?: boolean,
  isFetching?: boolean,
  footer?: any,
  withMaxHeight?: boolean,
  containerClassName?: string,
  noDataText?: string,
  children?: any,
  headCellClassName?: string,
  headerRender?: any,
  stickyHeader?: boolean,
  highlight?: boolean
  headerHeight?: number | null
}
export const CustomTable = ({
                              headItems = [],
                              isEmpty = false,
                              isFetching = false,
                              footer = null,
                              withMaxHeight = true,
                              containerClassName = "",
                              noDataText = "",
                              children,
                              headCellClassName = "",
                              headerHeight = null,
                              headerRender = null,
                              stickyHeader = false,
                              highlight = false
                            }: CustomTableType) => {
  const classes = useStyles({headerHeight});
  return (
    <div
      className={clsx(
        containerClassName,
        footer ? classes.containerFooter : ""
      )}
    >
      <WrapperScrollbar
        className={clsx(
          classes.container,
          containerClassName,
          {[classes.tableContainer]: !!withMaxHeight},
          {[classes.tableFooter]: footer},
          {[classes.containerFooterHeight]: footer}
        )}
      >
        <Table
          aria-label="simple table"
          stickyHeader={stickyHeader}
          classes={{root: clsx({[classes.table]: highlight})}}
        >
          <TableHead className={classes.headRow}>
            {!!headerRender && headerRender()}
            <TableRow>
              {headItems?.map((item, i) => {
                return (
                  <TableCell
                    key={i}
                    classes={{
                      stickyHeader: !!headerRender ? classes.stickyHeader : ""
                    }}
                    className={clsx(
                      classes.headCell,
                      headCellClassName,
                      item?.className || ""
                    )}
                    align={item?.align || "center"}
                  >
                    {!!item?.render ? (
                      <div className={classes.headText}>{item.render()}</div>
                    ) : (
                      <HeadItemText>{item.label || ""}</HeadItemText>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {!isFetching && <TableBody>{children}</TableBody>}
        </Table>
        {isFetching ? <div className={classes.loaderWrapper}>
          <LoaderData small/>
        </div> : isEmpty && <NotFound>{noDataText || "Ничего не найдено"}</NotFound>}
      </WrapperScrollbar>
      {!!footer && (
        <div className={classes.footer}>
          <TableRow>
            <TableCell align={"left"}>{"Всего"}</TableCell>
            <TableCell align={"right"}>{"20000"}</TableCell>
          </TableRow>
        </div>
      )}
    </div>
  );
};

type CustomTableRowType = {
  children?: any,
  className?: string,
  fixedDirection?: string,
  fixed?: boolean,
  isBinaryDiv?: boolean,
  highlight?: boolean,
}
export const CustomTableRow = ({
                                 children,
                                 className,
                                 fixedDirection = "bottom",
                                 fixed = false,
                                 isBinaryDiv = true,
                                 highlight = false,
                                 ...other
                               }: CustomTableRowType) => {
  const classes = useStyles({});
  return (
    <TableRow
      className={clsx(
        classes.row,
        {
          [`${classes.fixedRow} ${classes[`fixed_${fixedDirection}`]}`]: fixed
        },
        {[classes.highlightRow]: highlight},
        {[classes.classicDiv]: isBinaryDiv},
        {[classes.alternativeDiv]: isBinaryDiv === false},
        className
      )}
      {...other}
    >
      {children}
    </TableRow>
  );
};


type CustomTableCellType = {
  children?: any,
  className?: string,
  fixedDirection?: string,
  fixed?: boolean,
  openTab?: boolean,
  align?: "left" | "right" | "inherit" | "center" | "justify",
  isBinaryDiv?: boolean,
  highlight?: boolean,
}
export const CustomTableCell = ({
                                  children,
                                  className,
                                  openTab,
                                  align,
                                  fixed = false,
                                  ...other
                                }: CustomTableCellType) => {
  const classes = useStyles({});
  return (
    <TableCell
      align={align || "left"}
      className={clsx(
        classes.border,
        {[classes.fixedCell]: fixed},
        {[classes.borderless]: openTab === false},
        className
      )}
      {...other}
    >
      {children}
    </TableCell>
  );
};

export const HeadItemText = ({children}) => (
  <span className={useStyles({}).headText}>{children}</span>
);
