import {appAPI} from "./api_app";
import {Company} from "../types";

export const getPositions = async () => {
  try {
    const res = await appAPI.getPositions()
    return res?.data
  } catch (e) {}
}
export const getCompanies = async (): Promise<Company[]> => {
  try {
    const res = await appAPI.getCompanies()
    return res?.data
  } catch (e) {}
}