import React, {useEffect, useState} from "react";
import {CustomNativeSelect} from "../../../form/CustomNativeSelect";
import {getModulesData, updateActiveModuleId} from "./services";
import { setModules as setModulesList, setActiveModuleId as setModuleId } from "../../../../redux/reducers/appReducer";
import {useAppDispatch} from "../../../../redux";
import {Module} from "../../../../types";

export const SelectModule = () => {
  const dispatch = useAppDispatch();
  const [modules, setModules] = useState<Module[]>([])
  const [activeModuleId, setActiveModuleId] = useState<number | null>(null)

  useEffect(() => {
    const getData = async () => {
      const {modules, moduleId} = await getModulesData()
      dispatch(setModuleId(moduleId))
      dispatch(setModulesList(modules))
      setActiveModuleId(moduleId)
      setModules(modules)
    }
    getData()
  }, [dispatch])

  const onOptionClick = async e => {
    const moduleId = Number(e.target?.value);
    if (moduleId === activeModuleId) return;
    await updateActiveModuleId(moduleId)
  };

  return (
    <div>
      <CustomNativeSelect
        label={"Модуль"}
        optionDisplay={"module_name"}
        optionKey={"module_id"}
        optionValue={"module_id"}
        options={modules}
        placeholder={"Выберите модуль"}
        value={activeModuleId}
        handleChange={onOptionClick}
      />
    </div>
  );
};
