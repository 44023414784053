import React from "react";
import {Box, Typography} from "@material-ui/core";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import {BaseTextFieldProps} from "@material-ui/core/TextField/TextField";

const StyledTextField = styled(TextField)`
  .MuiInput-underline:before {
    border-bottom: 1px solid #888888;
  }

  && .MuiInput-underline:hover:before {
    border-bottom: 1px solid #888888;
  }

  && .MuiInputBase-input {
    text-transform: ${props =>
            props.uppercase === "true" ? "uppercase" : "none"};
  }

  && .MuiInputBase-input::placeholder {
    text-transform: none;
  }

  .MuiInput-underline:after {
    border-bottom: 1px solid #888888;
  }

  color: #000;
`;

interface CustomInputType extends BaseTextFieldProps {
  placeholder?: string,
  name?: string
  type?: string,
  label?: string,
  handleChange?: () => void,
  onChange?: () => void,
  handleBlur?: () => void,
  value?: string,
  title?: string,
  min?: number,
  wrapperClassname?: string,
  disabled?: boolean,
  uppercase?: boolean,
  multiline?: boolean,
  required?: boolean,
  inputClassname?: string,
  error?: boolean,
  helperText?: string,
  titleClassname?: string,
  onClick?: () => void
}

const CustomInput = ({
                       placeholder = "",
                       name = "",
                       type = "text",
                       label = "",
                       handleChange = () => {
                       },
                       handleBlur = () => {
                       },
                       value = "",
                       variant = "standard",
                       title = "",
                       min = 0,
                       wrapperClassname = "",
                       disabled = false,
                       uppercase = false,
                       multiline = false,
                       required = false,
                       inputClassname = "",
                       error = false,
                       helperText,
                       titleClassname = "mb-2 mt-1",
                       onClick = () => {
                       },
                       ...other
                     }: CustomInputType) => {
  return (
    <Box className={wrapperClassname} sx={{width: "100%"}}>
      <Typography className={titleClassname}>{title}</Typography>
      <StyledTextField
        name={name}
        placeholder={placeholder}
        fullWidth
        label={label}
        helperText={helperText}
        margin={"none"}
        onChange={handleChange}
        onClick={onClick}
        onBlur={handleBlur.bind(null, name)}
        value={value}
        variant={variant}
        multiline={multiline}
        error={Boolean(error)}
        required={required}
        disabled={disabled}
        inputProps={{className: inputClassname, type: type, min}}
        type={type}
        {...other}
        uppercase={uppercase ? "true" : "false"}
      />
    </Box>
  );
};

export default CustomInput;
