import React, {useMemo} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {FormHelperText, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

interface AutoSuggestType {
  label?: string,
  placeholder?: string,
  name?: string,
  value?: string | number | string[] | number[],
  onChange: (e: any, c: any) => void,
  error?: boolean,
  errMessage?: string,
  list: any[],
  showKey?: string,
  valueKey?: string,
  required?: boolean,
  multiple?: boolean,
  min?: boolean,
  withMargin?: boolean,
  defaultValue?: string
  renderOption?: (n: any) => void
}

export const AutoSuggest = ({
                              label = "",
                              name = "",
                              value = "",
                              onChange,
                              error = false,
                              errMessage,
                              list: items = [],
                              showKey = "name",
                              valueKey = "id",
                              required = false,
                              multiple = false,
                              min = false,
                              withMargin = false,
                              defaultValue = "",
                              ...other
                            }: AutoSuggestType) => {
  const classes = styles();

  const list = useMemo(() => {
    try {
      if (!items || !Array.isArray(items))
        return []
      return items
    } catch (e) {
      return []
    }
  }, [items])

  function handleChange(event, valueObj) {
    const e = {target: {name, value: valueObj ? valueObj[valueKey] : ""}};
    if (multiple) {
      e.target.value = valueObj.map(el => el[valueKey]);
    }
    return onChange && onChange(e, valueObj);
  }

  const inputValue = useMemo(() => {
    if (multiple && typeof value === "object") {
      if (!value?.length) return [];
      try {
        return value?.map(id => {
          const current = list?.find(listItem => listItem[valueKey] === id);
          return current || {};
        });
      } catch (e) {
        return [];
      }
    }

    if (typeof value === "object") return value;
    const item = list?.find(item => item[valueKey] === value);
    return item || "";
  }, [value, list, valueKey, multiple]);

  return (
    <div className={classes.wrapper}>
      <Autocomplete
        id={name}
        //@ts-ignore
        name={name}
        multiple={multiple}
        noOptionsText="Не найдено"
        options={list || []}
        getOptionLabel={option =>
          (typeof option[showKey] === "number"
            ? option[showKey].toString()
            : option[showKey]) || ""
        }
        classes={{option: clsx(classes.option, {[classes.optionMin]: min})}}
        value={inputValue}
        onChange={handleChange}
        renderInput={params => (
          <TextField name={name} {...params} className="w-100" label={label}/>
        )}
        {...other}
      />
      {error ? (
        <FormHelperText error={error}>{errMessage}</FormHelperText>
      ) : null}
    </div>
  );
};

const styles = makeStyles(() => ({
  option: {
    fontSize: 14
  },
  optionMin: {
    fontSize: 12
  },
  wrapper: {
    width: "100%"
  }
}));
