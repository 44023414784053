import instance from "../../../api";
import {GetEmployeesResponse} from "../../ManageAccess/types";
import {GetContragentsRes} from "../types";
import {
  GetEmployeeCitiesType,
  GetEmployeeCompaniesType,
  GetEmployeeModulesType,
  GetEmployeeRolesType
} from "../info/types";
import {GetTeamMastersRes} from "../../../types";

export const getEmployees = async (params = {}): Promise<GetEmployeesResponse> => {
  return await instance(true)
    .get("/core/employees/", {params})
    .then(res => res?.data);
};
export const getUserCities = async (employee_id: number): Promise<GetEmployeeCitiesType> => {
  return await instance(true)
    .post("/core/employees/employee-cities/", {employee_id})
    .then(res => res?.data);
};
export const getUserRoles = async (employee_id: number): Promise<GetEmployeeRolesType> => {
  return await instance(true)
    .post(`/core/employees/employee-groups/`, {employee_id})
    .then(res => res?.data);
};
export const getEmployeeModules = async (employee_id: number): Promise<GetEmployeeModulesType> => {
  return await instance(true)
    .post(`/core/employees/employee-modules/`, {employee_id})
    .then(res => res?.data);
};
export const getEmployeeCompanies = async (employee_id: number): Promise<GetEmployeeCompaniesType> => {
  return await instance(true)
    .post(`/core/employees/employee-companies/`, {employee_id})
    .then(res => res?.data);
};

export const getContragents = async (): Promise<GetContragentsRes> => {
  return await instance(true)
    .get("/core/commons/contragents/")
    .then(res => res?.data);
};
export const getTeamMastersByGroup = async (group_code: string): Promise<GetTeamMastersRes> => {
  return await instance(true)
    .post("/core/commons/team_masters/by_group/", {group_code})
    .then(res => res?.data);
};

