import React, {useEffect, useState} from "react";
import {alpha, withStyles} from "@material-ui/core/styles";
import {TreeItem, TreeView} from "@material-ui/lab";
import {Checkbox, makeStyles, SvgIcon, Tooltip} from "@material-ui/core";
import {Timer} from "@material-ui/icons";
import EmptyOrShow from "../../EmptyOrShow";
import {getLocaleDate} from "../../../utils";
import {CustomMoreActions} from "../CustomMoreActions";
import {AddIconBtn, EditIconBtn, RemoveIconBtn, TimerIconBtn} from "../index";

type CustomTreeViewType = {
  data: any
  addItem: (n: number) => void,
  expandedTree: any[],
  updateItem: (n:any) => void,
  deleteItem: (n: number) => void,
  valueOption?: string,
  labelOption?: string,
  isFetching?: boolean,
  onLabelClick: (n: number) => void,
  onSelectChange: (e: React.ChangeEvent<HTMLInputElement>, n: number) => void,
  onTimerClick: (n: number) => void,
  showTimer?:boolean,
  activeItemId: number,
  subMenuOption?: string
}
export const CustomTreeView = ({
  data,
  addItem,
  expandedTree,
  updateItem,
  deleteItem,
  valueOption = "menu_id",
  labelOption = "menu_name",
  isFetching = false,
  onLabelClick,
  onSelectChange,
  onTimerClick,
  showTimer = false,
  activeItemId,
  subMenuOption = "sub_menus"
}: CustomTreeViewType) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    if (!!expandedTree?.length) setExpanded(expandedTree);
    else setExpanded([]);
  }, [expandedTree]);

  const onNodeToggle = (e, value) => {
    const { ownerSVGElement } = e.target;
    const id = e.target?.id || ownerSVGElement?.id;
    if (id === "collapse" || id === "collapsed") setExpanded(value);
  };

  const renderNestedItems = (data) => {
    return data?.map(item => {
      const actions = [
        {
          content: <EditIconBtn onClick={() => updateItem && updateItem(item)} />
        },
        {
          content: (
            <RemoveIconBtn
              onClick={() => deleteItem && deleteItem(item[valueOption])}
            />
          )
        },
        {
          content: (
            <AddIconBtn
              onClick={() => addItem && addItem(item[valueOption])}
            />
          )
        }
      ];
      if (!item?.is_chosen && showTimer) {
        actions.push({
          content: (
            <TimerIconBtn
              onClick={() => onTimerClick && onTimerClick(item[valueOption])}
            />
          )
        });
      }
      const isActive = activeItemId === item[valueOption];
      return (
        <StyledTreeItem
          key={item[valueOption]}
          nodeId={item[valueOption]?.toString()}
          label={
            <div className={classes.treeItem}>
              <div className={classes.itemContent}>
                <div>
                  <Checkbox
                    indeterminate={item.indicator_status === 'half'}
                    checked={item?.is_chosen}
                    onChange={e => onSelectChange(e, item[valueOption])}
                  />
                  <p className={classes.label}>
                      <span
                        className={`${classes.label_main} ${
                          isActive ? classes.activeItem : ""
                        }`}
                      >
                        <span
                          onClick={() =>
                            onLabelClick && onLabelClick(item[valueOption])
                          }
                        >
                          {`${item[labelOption]} ${
                            item?.order_num ? `(${item?.order_num})` : ""
                          }`}
                        </span>
                        {!!item?.grant_code && (
                          <span className={classes.label_desc} title={'Код'}>
                          {item?.grant_code}
                        </span>
                        )}
                        {item?.date_begin && (
                          <Tooltip
                            title={`${getLocaleDate(
                              item?.date_begin
                            )} - ${getLocaleDate(item?.date_end)}`}
                            aria-label="date"
                          >
                            <Timer className={classes.timerIcon} />
                          </Tooltip>
                        )}
                      </span>
                  </p>
                </div>
                <CustomMoreActions actions={actions}  />
              </div>
            </div>
          }
        >
          {!!item[subMenuOption]?.length &&
            renderNestedItems(item[subMenuOption])}
        </StyledTreeItem>
      );
    });
  };

  return (
    <EmptyOrShow loading={isFetching}>
      <TreeView
        disableSelection
        onNodeToggle={onNodeToggle}
        className={classes.root}
        expanded={expanded}
        unselectable={'off'}
        defaultCollapseIcon={<MinusSquare id="collapse" />}
        defaultExpandIcon={<PlusSquare id="collapsed" />}
        defaultEndIcon={<div />}
      >
        {renderNestedItems(data)}
      </TreeView>
    </EmptyOrShow>
  );
};

const StyledTreeItem = withStyles(theme => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3
    }
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
  }
}))((props: { nodeId: string, children: any, label: any }) => <TreeItem  {...props} />);

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
  },
  treeItem: {
    display: "flex",
    alignItems: "center",
    gap: "2px",
    flexWrap: "nowrap"
  },
  itemContent: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: 2,
    justifyContent: "space-between",
    paddingRight: 5,
    "& > div": {
      display: "flex",
      alignItems: "center",
      gap: 2
    }
  },
  label: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    margin: 0
  },
  label_main: {
    lineHeight: "12px",
    display: "flex",
    alignItems: "center"
  },
  label_desc: {
    fontSize: "12px",
    color: "#7c7c7c",
    marginTop: "1px",
    marginLeft: "3px"
  },
  activeItem: {
    color: "#da0d0d"
  },
  timerIcon: {
    fontSize: 15,
    color: "#545454",
    marginLeft: 3
  },
});

const MinusSquare = props => {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
};

const PlusSquare = props => {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
};
