import {CustomSearch} from "../../../components/form/CustomSearch";
import styles from "../manage-access.module.scss";
import {CButton} from "../../../components/buttons";
import AddIcon from "@material-ui/icons/Add";
import {AiOutlineReload} from "react-icons/ai";
import clsx from "clsx";
import {CustomMoreActions} from "../../../components/blocks/CustomMoreActions";
import {NotFound} from "../../../components/text";
import React, {useMemo, useState} from "react";
import {CopyIconBtn, EditIconBtn, RemoveIconBtn} from "../../../components/blocks";
import {closePopUp, showPopUp} from "../../../redux/reducers/appReducer";
import {MODALS_NAMES} from "../../../constants";
import {useHttp} from "../../../utils/hooks/http.hook";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";
import {useDispatch} from "react-redux";
import EmptyOrShow from "../../../components/EmptyOrShow";
import {RequestResponse, Role} from "../../../types";
import {GetGroupsResponse, SelectedObjType} from "../types";
import {ConfirmModalData} from "../../../components/popup/types";
import {NotInterested} from '@material-ui/icons';

type SelectRolesType = {
  selectedObj: SelectedObjType
  data: Role[] | null,
  resetData: () => void,
  handleChangeRolesData: (n: Role[]) => void,
  resetSelectedObj: () => void
  setSelectedObj: (n: SelectedObjType) => void
  isFetching?: boolean
}

export const SelectRoles = ({
                              selectedObj,
                              data,
                              resetData,
                              resetSelectedObj,
                              handleChangeRolesData,
                              setSelectedObj,
                              isFetching
                            }: SelectRolesType) => {
  const dispatch = useDispatch();
  const {request} = useHttp();
  const {addDefaultSnack} = useCustomSnackbar();
  const [search, setSearch] = useState("");

  const updateData = (groups) => {
    handleChangeRolesData(groups);
    addDefaultSnack();
    dispatch(closePopUp());
  }

  const deleteItem = async groupId => {
    const res = await request<GetGroupsResponse>(
      `/core/groups/${groupId}/`,
      "delete",
      {},
      {},
      {isModal: true}
    );
    if (res?.status) {
      if (selectedObj.type === "roles" && selectedObj.id === groupId) resetSelectedObj()
      updateData(res?.groups);
    }
  };

  const onDeleteClick = groupId => {
    dispatch(
      showPopUp(MODALS_NAMES.confirmModal, {onSubmit: () => deleteItem(groupId)} as ConfirmModalData)
    );
  };

  const addRole = async body => {
    const res = await request<GetGroupsResponse>(
      `/core/groups/`,
      "post",
      {...body},
      {},
      {isModal: true}
    );
    if (res?.status) {
      updateData(res?.groups);
    }
  };

  const showAddRoleModal = () => {
    dispatch(
      showPopUp(MODALS_NAMES.addGroup, {
        onSubmit: addRole,
        close: true
      })
    );
  };

  const editRole = async (body, group_id) => {
    const res = await request<GetGroupsResponse>(
      `/core/groups/${group_id}/`,
      "put",
      {...body},
      {},
      {isModal: true}
    );
    if (res?.status) {
      updateData(res?.groups);
    }
  };

  const showEditRoleModal = role => {
    dispatch(
      showPopUp(MODALS_NAMES.addGroup, {
        onSubmit: body => editRole(body, role?.group_id),
        close: true,
        body: role
      })
    );
  };

  const submitCopyRole = async (body, selectedObj: { id: number, name: string }) => {
    const res = await request<RequestResponse>('/core/groups/group-permissions/duplicate/', 'post', body)
    if (res?.status) {
      setSelectedObj({
        type: "roles",
        ...selectedObj, time: new Date().getTime()
      })
      dispatch(closePopUp())
      addDefaultSnack()
    }
  }

  const copyRole = (group) => {
    dispatch(
      showPopUp(MODALS_NAMES.duplicatePermissions, {
        onSubmit: (body, selected) =>
          submitCopyRole({
            ...body,
            from_group_id: group.group_id,
          }, {id: selected.group_id, name: selected.group_name}),
        close: true,
        list: data?.filter(item => item.group_id !== group.group_id),
        type: 'role',
        targetName: group.group_name
      })
    );
  }

  const rolesActions = [
    {
      content: <EditIconBtn/>,
      onClick: (item) => showEditRoleModal(item)
    },
    {
      content: <RemoveIconBtn/>, onClick: (item) => onDeleteClick(item?.group_id)
    },
    {
      content: <CopyIconBtn title={'Дублировать роль'}/>,
      onClick: (group) => copyRole(group)
    }
  ]
  const handleChangeSearch = e => {
    setSearch(e.target.value);
  };

  const onClickOption = (e, item) => {
    setSelectedObj({
      type: "roles",
      id: item?.group_id,
      name: item?.group_name
    })
  };

  const computedRoles = useMemo(() => {
    if (!search || !data) return data;
    const searchTerm = search.toLowerCase();
    return data?.filter(item =>
      item?.group_name?.toLowerCase().includes(searchTerm)
    );
  }, [data, search]);

  return <EmptyOrShow loading={isFetching}>
    <CustomSearch handleChange={handleChangeSearch} value={search}/>
    <div
      className={`${styles.manage__menu_btnWrapper} ${styles.manage__menu_btnWrapperMargin}`}
    >
      <CButton
        className={styles.manage__menu_btn}
        onClick={showAddRoleModal}
      >
        Добавить <AddIcon/>
      </CButton>
      <CButton
        disabled={isFetching}
        label={"Сброс"}
        className={`${styles.manage__menu_btn} ${styles.manage__menu_btnReset}`}
        onClick={resetData}
      >
        <AiOutlineReload color={"#ffffff"} width={25} height={25}/>
      </CButton>
    </div>
    {!!computedRoles?.length ? (
      <ul className={styles.manage__select_list}>
        {computedRoles?.map(item => (
          <li
            className={clsx(styles.manage__select_listItem, {
              [styles.manage__select_listItemActive]:
              selectedObj?.type === "roles" &&
              selectedObj?.id === item?.group_id,
            })}
            key={item?.group_id}
            onClick={(e) => onClickOption(e, item)}
          >
            <span title={'Не активна'} className={styles.manage__select_listItemLabel}>{item?.group_name}
              {!item.is_active && <NotInterested fontSize={'small'} className={styles.manage__select_listItemIcon}/>}
            </span>
            <CustomMoreActions zeroPadding actions={rolesActions} value={item}/>
          </li>
        ))}
      </ul>
    ) : (
      <NotFound/>
    )}
  </EmptyOrShow>
}