import React, {useEffect, useState} from "react";
import styles from "./ReportAccessList.module.scss";
import {CustomTable, CustomTableCell, CustomTableRow} from "../../../components/blocks/CustomTable/CustomTable";
import {getLocaleDate} from "../../../utils";
import {PageTitle} from "../../../components/pageTitle";
import {getReportAccess} from "./services";
import {ReportGrant, ReportMenu} from "./types";

export const ReportAccessList = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState<{menu: ReportMenu[], grant: ReportGrant[]}>(null);

  useEffect(() => {
    const getData = async () => {
      const {menu, grant} = await getReportAccess(setIsFetching)
      setData({menu, grant});
    };
    getData();
  }, []);

  return (
    <>
      <PageTitle title={"Отчет по доступам"} />
      <div className={styles.report__wrapper}>
        <div className={styles.report__content}>
          <div>
            <h1 className={styles.report__title}>Меню</h1>
            <CustomTable
              isFetching={isFetching}
              containerClassName={styles.report__table}
              headItems={headItems}
              isEmpty={!data?.menu?.length}
            >
              {data?.menu?.map((item, i) => {
                return (
                  <CustomTableRow key={i}>
                    <CustomTableCell>{item?.menu_name}</CustomTableCell>
                    <CustomTableCell align={"center"}>
                      {getLocaleDate(item?.date_begin)}
                    </CustomTableCell>
                    <CustomTableCell align={"center"}>
                      {getLocaleDate(item?.date_end)}
                    </CustomTableCell>
                  </CustomTableRow>
                );
              })}
            </CustomTable>
          </div>
          <div>
            <h1 className={styles.report__title}>Права</h1>
            <CustomTable
              isFetching={isFetching}
              containerClassName={styles.report__table}
              headItems={headItems}
              isEmpty={!data?.grant?.length}
            >
              {data?.grant?.map((item, i) => {
                return (
                  <CustomTableRow key={i}>
                    <CustomTableCell>{item?.grant_name}</CustomTableCell>
                    <CustomTableCell align={"center"}>
                      {getLocaleDate(item?.date_begin)}
                    </CustomTableCell>
                    <CustomTableCell align={"center"}>
                      {getLocaleDate(item?.date_end)}
                    </CustomTableCell>
                  </CustomTableRow>
                );
              })}
            </CustomTable>
          </div>
        </div>
      </div>
    </>
  );
};

const headItems = [
  { label: "Название" },
  { label: "Дата начала" },
  { label: "Дата окончания" }
];
