import React, {useCallback} from "react";
import {useSnackbar, VariantType} from "notistack";
import {IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export const useCustomSnackbar = () => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const addDefaultSnack = useCallback(
    (message = 'Успешно', status: VariantType = "success", duration = 3000) => {
      if (!message) return;
      enqueueSnackbar(message, {
        variant: status,
        autoHideDuration: duration
      });
    },
    [enqueueSnackbar]
  );

  const addCloseSnack = useCallback(
    (message = null, status: VariantType = "error", duration = 8000) => {
      if (!message) return;
      const key = enqueueSnackbar(message, {
        variant: status,
        autoHideDuration: duration,
        action: () => (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon/>
          </IconButton>
        )
      });
    },
    [closeSnackbar, enqueueSnackbar]
  );

  return {addCloseSnack, addDefaultSnack};
};
