import React from "react";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

let useSnackbarRef;
export const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export default {
  success(msg: string, duration?: number) {
    this.toast(msg, "success", duration);
  },
  warning(msg: string, duration?: number) {
    this.toast(msg, "warning", duration);
  },
  info(msg: string, duration?: number) {
    this.toast(msg, "info", duration);
  },
  error(msg: string, duration?: number) {
    this.toast(msg, "error", duration);
  },
  toast(msg: string, variant = "default", autoHideDuration = 8000) {
    const key = useSnackbarRef.enqueueSnackbar(msg, {
      variant,
      autoHideDuration,
      action: () => (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() =>
            useSnackbarRef?.closeSnackbar && useSnackbarRef?.closeSnackbar(key)
          }
        >
          <CloseIcon />
        </IconButton>
      )
    });
  }
};
