import {getModules, updateUserModuleId} from "../../../../pages/ManageAccess/api";
import {Module} from "../../../../types";

const getActiveModule = (modules: Module[]): number => {
  if(modules) {
    const chosenModule = modules.find(item => item.is_chosen)
    return chosenModule?.module_id
  } return null
}

export const getModulesData = async(): Promise<{modules: Module[], moduleId: number | null}> => {
  try {
    const { modules } = await getModules();
    const moduleId = getActiveModule(modules)
    return {modules, moduleId}
  } catch (e) {
    return {modules: [], moduleId: null}
  }
}
export const updateActiveModuleId = async (moduleId: number) => {
  try {
    const res = await updateUserModuleId(moduleId);
    if(window && res) window.location.reload()
  } catch (e) {
  }
}
