import clsx from "clsx";
import Box from "@material-ui/core/Box";
import {Link} from "react-router-dom";
import SvgIcon from "@material-ui/core/SvgIcon";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import React, {Fragment, useEffect, useState} from "react";
import ListItem from "@material-ui/core/ListItem";
import Drawer from "@material-ui/core/Drawer";
import {useTheme} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {useStyles} from "../index";
import EmptyOrShow from "../../EmptyOrShow";
import {WrapperScrollbar} from "../../WrapperScrollbar";
import {appState} from "../../../redux/reducers/appReducer";
import {SidebarUserbox} from "../sidebarUserBox";
import {Chart, Chat, Document, Home, Paper, Search, Setting, Wallet} from "react-iconly";
import MenuItem from "./MenuItem";
import {PAGES} from "../../../constants";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {useLocation} from "react-router";
import {getMenuData} from "./services";
import {Menu as MenuType} from "../../../pages/ManageAccess/types"

type MenuComponentType = {
  changeOpenMenu: () => void
}
export const Menu = ({changeOpenMenu}: MenuComponentType) => {
  const theme = useTheme();
  const location = useLocation();
  const classes = useStyles();
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState<MenuType[] | null>(null);
  const {isMainMenuOpen: open} = useSelector(appState);

  useEffect(() => {
    const getMenu = async () => {
      const menu = await getMenuData(setIsFetching)
      setData(menu);
    }
    getMenu()
  }, []);

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={changeOpenMenu}
      variant="permanent"
      className={clsx(classes.drawer, "menu-layout", {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: clsx(classes.paper, "menu-layout", {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
    >
      <WrapperScrollbar className={clsx(classes.drawerContent)}>
        <div
          className={clsx(classes.toolbar, {
            [classes.toolbarOpen]: open,
            [classes.toolbarClose]: !open
          })}
        >
          <Link to={"/"}>
            <SvgIcon>
              <Logo/>
            </SvgIcon>
          </Link>
          <IconButton onClick={changeOpenMenu} className={classes.menuButton}>
            {theme.direction === "rtl" ? null : (
              <Box>
                <BurgerIcon/>
              </Box>
            )}
          </IconButton>
        </div>
        <div
          className={clsx({
            [classes.hide]: !open
          })}
        >
          <SidebarUserbox/>
        </div>
        <EmptyOrShow loading={isFetching}>
          <List style={{padding: "8px 16px", flexGrow: 1}}>
            {data?.map(
              ({menu_action, icon, menu_id, menu_name, sub_menus}) => {
                return (
                  <Fragment key={menu_id}>
                    {!!sub_menus?.length ? (
                      <MenuItem
                        icon={icon}
                        sub_menus={sub_menus}
                        menu_name={menu_name}
                        open={open}
                        setOpen={changeOpenMenu}
                        getIcon={getIcon}
                      />
                    ) : (
                      <Link
                        to={PAGES[menu_action] || "/"}
                        className={clsx(classes.listItem, {
                          [classes.active]:
                          location?.pathname === PAGES[menu_action]
                        })}
                      >
                        <MenuListItem open={open}>
                          <ListItemIcon
                            className={clsx({
                              [classes.mr]: open,
                              [classes.ml]: !open,
                              [classes.itemIconCollapsed]: !open,
                              [classes.active]:
                              window.location?.pathname === PAGES[menu_action]
                            })}
                          >
                            {getIcon(icon)}
                          </ListItemIcon>
                          {open && <ListItemText primary={menu_name}/>}
                        </MenuListItem>
                      </Link>
                    )}
                  </Fragment>
                );
              }
            )}
          </List>
        </EmptyOrShow>
      </WrapperScrollbar>
    </Drawer>
  );
};

export const MenuListItem = ({open, children, style = {}, ...other}) => {
  return (
    <ListItem
      style={{
        paddingLeft: open ? "10px" : 0,
        paddingRight: open ? "10px" : 0,
        justifyContent: open ? "flex-start" : "center",
        ...style
      }}
      {...other}
    >
      {children}
    </ListItem>
  );
};

const getIcon = (icon: string) => {
  switch (icon) {
    case "Home":
      return <Home set="light"/>;
    case "Wallet":
      return <Wallet set="light"/>;
    case "Chart":
      return <Chart set="light"/>;
    case "Document":
      return <Document set="light"/>;
    case "Chat":
      return <Chat set="light"/>;
    case "Paper":
      return <Paper set="light"/>;
    case "Search":
      return <Search set="light"/>;
    case "Setting":
      return <Setting set="light"/>;
    default:
      return null;
  }
};

const Logo = () => <svg
  width="30"
  height="30"
  viewBox="0 0 136 206"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M124.755 44.5148L117.494 37.2859C115.293 34.7915 112.073 33.2182 108.486 33.2182C101.854 33.2182 96.4774 38.5945 96.4774 45.2266C96.4774 49.8231 99.0599 53.8164 102.853 55.8347L135.158 75.3388C134.432 81.5279 132.844 87.6001 130.431 93.3794C127.009 101.576 121.992 109.022 115.669 115.293C109.345 121.564 101.839 126.537 93.5782 129.927C85.3175 133.318 76.4646 135.059 67.5255 135.052C54.4322 135.052 41.6167 131.304 30.6158 124.257C19.6148 117.21 10.8952 107.163 5.50267 95.3212L10.3572 90.5031L17.6472 97.7605C19.8477 100.258 23.0701 101.834 26.6606 101.834C33.2925 101.834 38.6688 96.4578 38.6688 89.8258C38.6688 85.2333 36.0908 81.2429 32.3031 79.2229L0 59.7201C1.76253 44.6852 8.5799 30.5877 19.4713 19.7779C32.2304 7.11434 49.5355 2.01112e-05 67.5797 2.01112e-05C80.6716 -0.0100044 93.4887 3.72772 104.494 10.7651C115.5 17.8025 124.227 27.8409 129.63 39.6769L124.755 44.5148Z"
    fill="#1375D1"
  />
</svg>

const BurgerIcon = () => <svg
  width="32"
  height="16"
  viewBox="0 0 32 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect
    x="6.83862"
    width="24.1936"
    height="2"
    rx="1"
    fill="#162541"
  />
  <rect
    x="0.0644531"
    y="7"
    width="30.9677"
    height="2"
    rx="1"
    fill="#162541"
  />
  <rect
    x="6.83862"
    y="14"
    width="24.1936"
    height="2"
    rx="1"
    fill="#162541"
  />
</svg>