import {getContragents, getEmployees, getTeamMastersByGroup} from "../api";
import {setModuleContragents, setRoles} from "../../../redux/reducers/appReducer";
import {getRolesData} from "../../ManageAccess/ManageAccessSelect/services";
import {Employee, Master} from "../../../types";

export const getEmployeesData = async (params, setIsFetching): Promise<Employee[]> => {
  try {
    setIsFetching(true);
    const {employees} = await getEmployees(params);
    setIsFetching(false);
    return employees || []
  } catch (e) {
    setIsFetching(false);
    return []
  }
};

export const getAllRolesData = () => async (dispatch) => {
  const groups = await getRolesData();
  dispatch(setRoles(groups));
}

const getActiveValue = {
  all: "all",
  active: true,
  inactive: false
};
export const filterUsers = (data, filters) => {
  if (!data?.length) return data;
  const searchLower = filters?.search.toLowerCase();
  const is_active = getActiveValue[filters?.is_active];
  try {
    return data?.filter(
      item =>
        (is_active === "all" ? true : !!item?.is_active === is_active) &&
        (item?.employee_id
            ?.toString()
            .toLowerCase()
            .includes(searchLower) ||
          item?.fio?.toLowerCase().includes(searchLower) ||
          item?.city_name?.toLowerCase().includes(searchLower) ||
          item?.email?.toLowerCase().includes(searchLower))
    );
  } catch (e) {
    return data;
  }
}

export const refactorContragentsList = (contragents) => {
  if (!contragents) return []
  const contragentsList = []

  contragents?.forEach(contragent => {
    const {contragent_name, contragent_id} = contragent
    contragentsList.push({contragent_name, contragent_id})
    if (!!contragent.sub_contragents?.length) {
      contragent?.sub_contragents?.forEach(sub => {
        const {contragent_name, contragent_id} = sub
        contragentsList.push({contragent_name, contragent_id, is_sub: true})
      })
    }
  })
  return contragentsList
}

export const getContragentsData = () => async (dispatch) => {
  try {
    const {contragents: contragentsList} = await getContragents();
    const contragents = refactorContragentsList(contragentsList)
    dispatch(setModuleContragents(contragents))
  } catch (e) {
    dispatch(setModuleContragents([]))
  }
};
export const getTeamMastersByGroupData = async (group_code: string): Promise<Master[]> => {
  try {
    const res = await getTeamMastersByGroup(group_code)
    return res?.team_masters
  } catch (e) {
  }
}
