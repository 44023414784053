import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

type PageTitleType = {
  title: string
}
export const PageTitle = ({ title }: PageTitleType) => {
  const classes = useStyles();
  return (
    <Box className={classes.pageTitle}>
      <Typography className={classes.pageTitleText}>
        {title}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  pageTitle: {
    background: "#FFFFFF",
    boxShadow: " 0px 5px 20px rgba(108, 108, 108, 0.15)",
    display: "flex",
    alignItems: "center",
    padding: "18px 0",
    height: "64px"
  },
  pageTitleText: {
    fontWeight: 500,
    fontSize: "20px",
    color: "#494C62",
    marginLeft: "50px",
    [theme.breakpoints.up(768)]: {
      marginLeft: 25
    },
    [theme.breakpoints.down(576)]: {
      marginLeft: 15
    }
  }
}));
