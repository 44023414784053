import {getUserProfileInfo} from "./api";
import {setUserData} from "../../../redux/reducers/appReducer";
import {SetIsFetchingType} from "../../../types";

export const getUserData = (setLoading: SetIsFetchingType) => async (dispatch) => {
  setLoading(true);
  await getUserProfileInfo()
    .then(answer => {
      const {user_info} = answer;
      dispatch(setUserData(user_info));
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
}