import {updateUserCompanyId} from "../../../../pages/ManageAccess/api";
import {Company} from "../../../../types";
import {appAPI} from "../../../../api/api_app";

export const getAppCompanies = async (): Promise<Company[]> => {
  try {
    const res = await appAPI.getAppCompanies()
    return res?.data
  } catch (e) {}
}

export const updateActiveCompanyId = async (companyId: number) => {
  try {
    const res = await updateUserCompanyId(companyId);
    if(window && res) window.location.reload()
  } catch (e) {
  }
}
