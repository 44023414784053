import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";
import {LoaderData} from "./LoaderData";
import clsx from "clsx";

interface CButtonType {
  loading?: boolean
  disabled?: boolean,
  small?: boolean,
  className?: string
  children?: any
  mode?: string,
  label?: string,
  onClick?: (n: any) => void,
  type?: "button" | "reset" | "submit",
}

export const CButton = ({
                          loading,
                          className,
                          disabled,
                          mode = "",
                          onClick = () => {
                          },
                          children,
                          small,
                          type,
                          ...other
                        }: CButtonType) => {
  const classes = useStyles();
  return <Button
    type={type || "submit"}
    disabled={disabled || loading}
    onClick={onClick}
    className={clsx(
      classes.btn,
      {[classes.btnAuto]: mode === "auto"},
      {[classes.btnSmall]: small},
      className || ""
    )}
    classes={{disabled: classes.disabled}}
    {...other}
  >
    {loading ? <LoaderData small white/> : children || "Добавить"}
  </Button>
};
const useStyles = makeStyles({
  btn: {
    width: 200,
    height: 38,
    padding: 10,
    borderRadius: 4,
    color: "#fff",
    backgroundColor: "#1375D1",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#1375D1"
    }
  },
  btnAuto: {
    width: "auto"
  },
  btnSmall: {
    height: "34px"
  },
  disabled: {
    backgroundColor: "rgba(70,70,70,0.12)",
    color: "#4f4f4f"
  }
});
