import React, {useEffect, useState} from "react";
import {useHttp} from "../../../utils/hooks/http.hook";
import {UserInfoContent} from "./Content";
import {getUserCitiesData} from "./services";
import {EmployeeCity} from "../../../types";
import {GetEmployeeCitiesType} from "./types";

type UserCitiesType = {
  activeUserId: number
  changeCities: (employee_id: number, city_name: string) => void
}
export const UserCities = ({ activeUserId, changeCities }: UserCitiesType) => {
  const { request, loading } = useHttp();
  const [data, setData] = useState<EmployeeCity[] | null>(null);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const employee_cities = await getUserCitiesData(activeUserId, setIsFetching);
      setData(employee_cities);
    };
    getData();
  }, [activeUserId]);

  const handleCheckChange = async city_id => {
    if (loading) return;
    const res = await request<GetEmployeeCitiesType>(
      `/core/employees/employee-cities/choose/`,
      "post",
      { city_id, employee_id: activeUserId }
    );
    if (res?.status) {
      setData(res?.employee_cities);
      changeCities(activeUserId, res?.cities);
    }
  };

  return (
    <UserInfoContent
      data={data}
      loading={loading}
      isFetching={isFetching}
      handleCheckChange={handleCheckChange}
      idKey={"city_id"}
      labelKey={"city_name"}
    />
  );
};
