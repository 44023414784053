import React, {Fragment, useEffect, useState} from "react";
import clsx from "clsx";
import {Box} from "@material-ui/core";
import {useSelector} from "react-redux";
import {PAGES} from "../../../constants";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import EmptyOrShow from "../../EmptyOrShow";
import {appState, setAuth} from "../../../redux/reducers/appReducer";
import cookiesService from "../../../services/cookiesService";
import {useNavigate} from "react-router";
import {getUserData} from "./services";
import {SelectModule} from "./SelectModule";
import {useAppDispatch} from "../../../redux";
import {SelectCompany} from "./SelectCompany";

const cookiesServiceInstance = cookiesService.getService();
export const SidebarUserbox = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {dataUser} = useSelector(appState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!dataUser) dispatch(getUserData(setLoading))
  }, [dispatch, dataUser]);

  const logout = () => {
    dispatch(setAuth(false))
    navigate(PAGES.login)
    cookiesServiceInstance.clearToken();
  };
  return (
    <Fragment>
      <Box
        className={clsx("app-sidebar-userbox__wrapper")}>
        <Box
          className={clsx("app-sidebar-userbox")}
        >
          <EmptyOrShow loading={loading}>
            <ExitToAppIcon
              onClick={logout}
              style={{
                transform: "rotate(180deg)",
                color: "black",
                cursor: "pointer"
              }}
              className="profile__icons"
            />
            <Box className="app-sidebar-userbox-name">
              <Box style={{lineHeight: "1.2"}}>
                <b>{dataUser?.fio}</b>
              </Box>
              <Box className="app-sidebar-userbox-position ">
                {dataUser?.bpm_position}
              </Box>
              <Box className="app-sidebar-userbox-description">
                {dataUser?.email}
              </Box>
              <Box className="app-sidebar-userbox-description">
                {dataUser?.phone}
              </Box>
            </Box>
          </EmptyOrShow>
        </Box>
        <SelectModule/>
        <SelectCompany/>
      </Box>
    </Fragment>
  );
};

