import axios from "axios";
import instance from "./index";
import cookiesService from "../services/cookiesService";
import {useDispatch} from "react-redux";
import {setAuth, setInitialize} from "../redux/reducers/appReducer";

const refreshTokenEndpoint = "/auth/api/token/refresh/";
const cookiesServiceInstance = cookiesService.getService();

let dispatch;
export const DispatchComponent = () => {
  dispatch = useDispatch();
  return null;
};

const refreshTokenAndRedirect = () => {
  cookiesServiceInstance.clearToken();
  dispatch(setAuth(false));
};
const tokenRefresher = async error => {
  const originalRequest = error.config;
  if (error.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    let refresh = cookiesServiceInstance.getRefreshToken();
    if (!refresh) {
      return refreshTokenAndRedirect();
    }

    return await instance(false, null, { throwError: true, showToast: false })
      .post(refreshTokenEndpoint, {
        refresh
      })
      .then(res => {
        if (res?.status >= 200) {
          dispatch(setAuth(true));
          const access = res?.data?.access;
          cookiesServiceInstance.setAccessToken(access);
          if (window) window.location.reload();
        }
      })
      .catch(() => {
        refreshTokenAndRedirect();
        dispatch(setInitialize());
      });
  }
};

export const handleError = async error => {
  if (axios.isCancel(error)) {
    return;
  }

  switch (parseInt(error.response?.status)) {
    case 404:
      break;
    case 401:
      if (error.response.config.url !== refreshTokenEndpoint) {
        return await tokenRefresher(error);
      } else {
        refreshTokenAndRedirect();
      }
      break;
    case 400:
      break;
    case 500:
      break;
    default:
      break;
  }
};
