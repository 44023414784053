import React, {useEffect, useState} from "react";
import {CustomNativeSelect} from "../../../form/CustomNativeSelect";
import {getAppCompanies, updateActiveCompanyId} from "./services";
import {useAppDispatch} from "../../../../redux";
import {Company} from "../../../../types";
import {useSelector} from "react-redux";
import {appState} from "../../../../redux/reducers/appReducer";

export const SelectCompany = () => {
  const dispatch = useAppDispatch();
  const [companies, setCompanies] = useState<Company[]>([])
  const {dataUser} = useSelector(appState);

  useEffect(() => {
    const getData = async () => {
      const companies = await getAppCompanies()
      if (!companies) return
      setCompanies(companies)
    }
    getData()
  }, [dispatch])

  const onOptionClick = async e => {
    const companyId = Number(e.target?.value);
    if (companyId === dataUser?.company_id) return;
    await updateActiveCompanyId(companyId)
  };

  return (
    <div style={{marginTop: "10px"}}>
      <CustomNativeSelect
        label={"Компания"}
        optionDisplay={"company_name"}
        optionKey={"company_id"}
        optionValue={"company_id"}
        options={companies}
        placeholder={"Выберите компанию"}
        value={dataUser?.company_id}
        handleChange={onOptionClick}
      />
    </div>
  );
};
