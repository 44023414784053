import {PageTitle} from "../../components/pageTitle";
import React from "react";
import {CompareDataOneCContent} from "./content";

export const CompareDataOneC = () => {
  document.title = "Сверка поступлений с 1C";
  return (
    <>
      <PageTitle title={"Сверка поступлений с 1C"}/>
      <CompareDataOneCContent/>
    </>
  );
}