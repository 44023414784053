import React, {useEffect, useState} from "react";
import {useHttp} from "../../../utils/hooks/http.hook";
import {UserInfoContent} from "./Content";
import {EmployeeCompany} from "../../../types";
import {getEmployeeCompaniesData} from "./services";
import {GetEmployeeCompaniesType} from "./types";
import styles from "../userSettings.module.scss";

type UserCompaniesType = {
  activeUserId: number
}
export const UserCompanies = ({ activeUserId }: UserCompaniesType) => {
  const { request, loading } = useHttp();
  const [isFetching, setIsFetching] = useState(false);
  const [companies, setCompanies] = useState<EmployeeCompany[]>([])

  useEffect(() => {
    const getData = async () => {
      const res = await getEmployeeCompaniesData(activeUserId, setIsFetching)
      if (!res) return
      setCompanies(res)
    }
    getData()
  }, [activeUserId])

  const handleCheckChange = async company_id => {
    if (loading) return;
    const res = await request<GetEmployeeCompaniesType>(
      `core/employees/employee-companies/choose/`,
      "post",
      { company_id, employee_id: activeUserId }
    );
    if (res?.status) {
      setCompanies(res?.employee_companies);
    }
  };

  const handleCheckGrantChange = async company_id => {
    if (loading) return;
    const res = await request<GetEmployeeCompaniesType>(
      `/core/employees/employee-companies/give-grant/`,
      "post",
      { company_id, employee_id: activeUserId }
    );
    if (res?.status) {
      setCompanies(res?.employee_companies);
    }
  };

  return (
    <div>
      <div className={styles.settings__info__content}>
        <UserInfoContent
          data={companies}
          loading={loading}
          isFetching={isFetching}
          handleCheckChange={handleCheckChange}
          handleCheckGrantChange={handleCheckGrantChange}
          idKey={"company_id"}
          labelKey={"company_name"}
        />
      </div>
    </div>
  );
};
