import {getEmployeeGrant, getEmployeeMenu, getGroupGrant, getGroupMenu} from "./api";
import {Grant, Menu, SelectedObjType} from "./types";
import {SetIsFetchingType} from "../../types";

export const getMenuData = async (selectedObj: SelectedObjType, setIsFetching: SetIsFetchingType):Promise<Menu[]> => {
  try {
    setIsFetching(true);
    if (selectedObj.type === "users") {
      const {employee_menu} = await getEmployeeMenu({
        employee_id: selectedObj.id
      });
      setIsFetching(false);
      return employee_menu
    } else {
      const {group_menu} = await getGroupMenu({ group_id: selectedObj.id });
      setIsFetching(false);
      return group_menu;
    }
  } catch (e) {
    setIsFetching(false);
    return []
  }
};

type MenuChooseBodyType = {
  employee_id?: number,
  menu_id: number,
  group_id?: number,
  change_to: boolean
}
export const getRequestData = (e, selectedObj: SelectedObjType, addBody: Menu, menu_id: number): {endpoint: string, body: MenuChooseBodyType} => {
  let endpoint = "core/employees/employee-menus/choose/";
  let body:MenuChooseBodyType = {
    employee_id: selectedObj?.id,
    menu_id,
    change_to: e.target.checked
  };
  if (addBody) {
    body = { ...body, ...addBody };
  }
  if (selectedObj?.type === "roles") {
    endpoint = "core/groups/group-menus/choose/";
    body = {
      group_id: selectedObj?.id,
      menu_id,
      change_to: e.target.checked
    };
  }
  return {endpoint, body}
}

export const getGrantData = async ( selectedObj: SelectedObjType, setIsFetching: SetIsFetchingType): Promise<Grant[]> => {
  try {
    setIsFetching(true);
    if (selectedObj.type === "users") {
      const {employee_grant} = await getEmployeeGrant({
        employee_id: selectedObj?.id
      });
      setIsFetching(false);
      return employee_grant
    } else {
      const {group_grant} = await getGroupGrant({
        group_id: selectedObj?.id
      });
      setIsFetching(false);
      return group_grant
    }
  } catch (e) {
    setIsFetching(false);
    return []
  }
};

type GrantChooseBodyType = {
  employee_id?: number,
  grant_id: number,
  group_id?: number,
  change_to: boolean
}
export const getSelectChangeRequestData = (e, selectedObj: SelectedObjType, addBody, grant_id): {body: GrantChooseBodyType, endpoint: string} => {
  const change_to = e.target.checked
  let endpoint = "/core/employees/employee-grants/choose/";
  if (selectedObj?.type === "roles") {
    endpoint = "/core/groups/group-grants/choose/";
  }
  let body: GrantChooseBodyType = {
    [selectedObj?.type === "roles" ? "group_id" : "employee_id"]: selectedObj?.id,
    grant_id,
    change_to
  };
  if (addBody) {
    body = { ...body, ...addBody };
  }
  return {body, endpoint}
}