import {CustomSearch} from "../../../components/form/CustomSearch";
import styles from "../manage-access.module.scss";
import {CButton} from "../../../components/buttons";
import {AiOutlineReload} from "react-icons/ai";
import clsx from "clsx";
import {CustomMoreActions} from "../../../components/blocks/CustomMoreActions";
import {NotFound} from "../../../components/text";
import React, {useMemo, useState} from "react";
import {closePopUp, showPopUp} from "../../../redux/reducers/appReducer";
import {MODALS_NAMES} from "../../../constants";
import {CopyIconBtn} from "../../../components/blocks";
import {useDispatch} from "react-redux";
import {useHttp} from "../../../utils/hooks/http.hook";
import EmptyOrShow from "../../../components/EmptyOrShow";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";
import {SelectedObjType} from "../types";
import {Employee, RequestResponse} from "../../../types";

type SelectUserType = {
  selectedObj: SelectedObjType
  data: Employee[] | null,
  resetData: () => void,
  setSelectedObj: (n: SelectedObjType) => void
  isFetching?: boolean
}
export const SelectUser = ({data, setSelectedObj, selectedObj, isFetching, resetData}: SelectUserType) => {
  const dispatch = useDispatch();
  const {request} = useHttp();
  const {addDefaultSnack} = useCustomSnackbar();
  const [search, setSearch] = useState("");

  const handleChangeSearch = e => {
    setSearch(e.target.value);
  };

  const computedUsers = useMemo(() => {
    if (!search || !data) return data;
    const searchTerm = search.toLowerCase();
    return data?.filter(item =>
      item?.fio?.toLowerCase().includes(searchTerm)
    );
  }, [data, search]);

  const submitCopyUser = async (body, selectedObj: { id: number, name: string }) => {
    const res = await request<RequestResponse>('/core/employees/employee-permissions/duplicate/', 'post', body)
    if (res?.status) {
      setSelectedObj({
        type: "users",
        ...selectedObj, time: new Date().getTime()
      })
      dispatch(closePopUp());
      addDefaultSnack()
    }
  }

  const copyUser = async employee => {
    dispatch(
      showPopUp(MODALS_NAMES.duplicatePermissions, {
        onSubmit: async (body, selected) =>
          await submitCopyUser({
            ...body,
            from_employee_id: employee.employee_id,
          }, {id: selected.employee_id, name: selected.fio}),
        close: true,
        list: data?.filter(item => item.employee_id !== employee.employee_id),
        type: 'user',
        targetName: employee.fio
      })
    );
  };


  const usersActions = [
    {
      content: <CopyIconBtn title={'Дублировать доступы пользователя'}/>, onClick: (employee) => copyUser(employee)
    }
  ]

  return <EmptyOrShow loading={isFetching}>
    <CustomSearch handleChange={handleChangeSearch} value={search}/>
    <div
      className={`${styles.manage__menu_btnWrapper} ${styles.manage__menu_btnWrapperMargin}`}
    >
      <div/>
      <CButton
        disabled={isFetching}
        label={"Сброс"}
        className={`${styles.manage__menu_btn} ${styles.manage__menu_btnReset}`}
        onClick={resetData}
      >
        <AiOutlineReload color={"#ffffff"} width={25} height={25}/>
      </CButton>
    </div>
    {!!computedUsers?.length ? (
      <ul className={styles.manage__select_list}>
        {computedUsers?.map(item => (
          <li
            className={clsx(styles.manage__select_listItem, {
              [styles.manage__select_listItemActive]:
              selectedObj?.type === "users" &&
              selectedObj?.id === item?.employee_id
            })}
            key={item?.employee_id}
            onClick={() => {
              setSelectedObj({
                type: "users",
                id: item?.employee_id,
                name: item?.fio
              })
            }
            }
          >
            {item?.fio}
            <CustomMoreActions zeroPadding actions={usersActions} value={item}/>
          </li>
        ))}
      </ul>
    ) : (
      <NotFound/>
    )}
  </EmptyOrShow>
}