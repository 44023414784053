import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {CButton} from "../../../buttons";
import styles from "./addEmployee.module.scss";
import {Checkbox, IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {AutoSuggest} from "../../../form/AutoSuggest";
import CustomInput from "../../../form/CustomInput";
import {InputWarning} from "../../../form/warnings";
import {appState} from "../../../../redux/reducers/appReducer";
import clsx from "clsx";
import {getTeamMastersByGroupData} from "../../../../pages/userSettings/services";
import {AddEmployeeProps} from "../types";
import {Company, Employee, Master, Position} from "../../../../types";
import {getCompanies, getPositions} from "../../../../api/services";

export const AddEmployee = ({data, handleClose}: AddEmployeeProps) => {
  const {
    handleSubmit,
    control,
    formState: {errors}, setValue
  } = useForm();
  const {
    modalLoading,
    moduleContragents: contragents,
    roles
  } = useSelector(appState);
  const [teamMasters, setTeamMasters] = useState<Master[]>([])
  const [positions, setPositions] = useState<Position[]>([])
  const [companies, setCompanies] = useState<Company[]>([])

  useEffect(() => {
    const getData = async () => {
      const res = await getPositions()
      if (!res) return
      setPositions(res)
    }
    getData()
  }, [])

  useEffect(() => {
    const getData = async () => {
      const res = await getCompanies()
      if (!res) return
      setCompanies(res)
    }
    getData()
  }, [])

  const onSubmit = async (body: Employee) => {
    const {onSubmit: submit} = data;
    return submit && submit({...body})
  };

  useEffect(() => {
    if (data?.body?.group_code)
      getMastersData(data?.body?.group_code)
  }, [data])

  const getMastersData = useCallback(async (group_code: string) => {
    const res = await getTeamMastersByGroupData(group_code)
    setTeamMasters(res || [])
  }, [])

  const onGroupChange = useCallback(async (group_id: number, group_code: string) => {
    getMastersData(group_code)
    setValue('team_master_id', '')
  }, []);

  return (
    <div>
      <div className={styles.add__header}>
        <h2 className={styles.add__title}>{` ${
          data?.body ? "Редактирование" : "Добавление"
        } пользователя`}</h2>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon/>
        </IconButton>
      </div>

      <form className={styles.add__form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.add__form_item}>
          <Controller
            name="fio"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                type="text"
                fullWidth
                label="ФИО"
                placeholder="Введите ФИО"
                wrapperClassname={styles.add__input_wrapper}
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.fio || ""}
            rules={{
              required: true
            }}
          />
          {errors.fio && <InputWarning>Это поле обязательно</InputWarning>}
        </div>
        <div className={styles.add__form_item}>
          <Controller
            name="email"
            control={control}
            render={({field: {onChange, value}}) => (
              <CustomInput
                type="text"
                fullWidth
                label="Email"
                placeholder="Введите Email"
                wrapperClassname={styles.add__input_wrapper}
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.email || ""}
            rules={{
              required: true
            }}
          />
          {errors.email && <InputWarning>Это поле обязательно</InputWarning>}
        </div>
        <div className={styles.add__form_item}>
          <Controller
            name="group_id"
            control={control}
            render={({field: {onChange, value}}) => (
              <AutoSuggest
                withMargin
                list={roles}
                showKey={"group_name"}
                valueKey={"group_id"}
                value={value}
                placeholder="Выберите роль"
                label="Выберите роль"
                onChange={(e, group) => {
                  onChange(e);
                  onGroupChange(e?.target?.value, group?.group_code);
                }}
              />
            )}
            defaultValue={data?.body?.group_id || ""}
          />
          {errors.group_id && (
            <InputWarning>Это поле обязательно</InputWarning>
          )}
        </div>
        {!!teamMasters?.length && <div className={styles.add__form_item}>
          <Controller
            name="team_master_id"
            control={control}
            render={({field: {onChange, value}}) => (
              <AutoSuggest
                withMargin
                list={teamMasters}
                showKey={"team_master_fio"}
                valueKey={"team_master_id"}
                value={value}
                placeholder="Выберите мастера"
                label="Выберите мастера"
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.team_master_id || ""}
          />
        </div>}
        <div className={styles.add__form_item}>
          <Controller
            name="position_id"
            control={control}
            render={({field: {onChange, value}}) => (
              <AutoSuggest
                withMargin
                list={positions}
                showKey={"position_name"}
                valueKey={"position_id"}
                value={value}
                placeholder="Выберите должность"
                label="Выберите должность"
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.position_id || ""}
          />
        </div>
        {
          !!contragents && <div className={styles.add__form_item}>
            <Controller
              name="contragent_id"
              control={control}
              render={({field: {onChange, value}}) => (
                <AutoSuggest
                  list={contragents}
                  showKey={"contragent_name"}
                  valueKey={"contragent_id"}
                  value={value}
                  withMargin
                  placeholder="Выберите контрагента"
                  label="Выберите контрагента"
                  onChange={e => {
                    onChange(e);
                  }}
                  renderOption={props => {
                    return (
                      <div className={clsx(styles.add__contragent, {[styles.add__contragent_sub]: !!props?.is_sub})}>
                        {props?.contragent_name}
                      </div>
                    );
                  }}
                />
              )}
              rules={{
                required: true
              }}
              defaultValue={data?.body?.contragent_id || ""}
            />
            {errors.contragent_id && (
              <InputWarning>Это поле обязательно</InputWarning>
            )}
          </div>}
        <div className={styles.add__form_item}>
          <Controller
            name="company_id"
            control={control}
            render={({field: {onChange, value}}) => (
              <AutoSuggest
                list={companies}
                showKey={"company_name"}
                valueKey={"company_id"}
                value={value}
                withMargin
                placeholder="Выберите компанию"
                label="Выберите компанию"
                onChange={e => {
                  onChange(e);
                }}
              />
            )}
            defaultValue={data?.body?.company_id || ""}
          />
          {errors.company_id && (
            <InputWarning>Это поле обязательно</InputWarning>
          )}
        </div>
        {!!data?.body && (
          <div className={styles.add__form_item}>
            <label htmlFor={"is_active_modal_check"}>Активный</label>
            <Controller
              name="is_active"
              control={control}
              render={({field: {onChange, value}}) => (
                <Checkbox
                  id={"is_active_modal_check"}
                  checked={!!value}
                  onChange={onChange}
                />
              )}
              defaultValue={
                data?.body?.is_active || data?.body?.is_active !== 0
              }
            />
          </div>
        )}
        <div className={styles.add__btn_wrapper}>
          <CButton
            loading={modalLoading}
            disabled={modalLoading}
            type="submit"
            className={styles.add__btn}
          >
            Сохранить
          </CButton>
        </div>
      </form>
    </div>
  );
};
