import {getEmployeeCompanies, getEmployeeModules, getUserCities, getUserRoles} from "../api";
import {EmployeeCity, EmployeeCompany, EmployeeModule, EmployeeRole, SetIsFetchingType} from "../../../types";

export const getUserRolesData = async (activeUserId: number, setIsFetching: SetIsFetchingType):Promise<EmployeeRole[]> => {
  if(!activeUserId) return []
  try {
    setIsFetching(true);
    const { employee_groups } = await getUserRoles(activeUserId);
    setIsFetching(false);
    return employee_groups
  } catch (e) {
    setIsFetching(false);
    return []
  }
}

export const getUserCitiesData = async (activeUserId: number, setIsFetching: SetIsFetchingType):Promise<EmployeeCity[]> => {
  if(!activeUserId) return []
  try {
    setIsFetching(true);
    const { employee_cities } = await getUserCities(activeUserId);
    setIsFetching(false);
    return employee_cities
  } catch (e) {
    setIsFetching(false);
    return []
  }
}

export const getModulesData = async (activeUserId: number, setIsFetching: SetIsFetchingType): Promise<EmployeeModule[]> => {
  if(!activeUserId) return []
  try {
    setIsFetching(true);
    const { employee_modules } = await getEmployeeModules(activeUserId);
    setIsFetching(false);
    return employee_modules
  } catch (e) {
    setIsFetching(false);
    return []
  }
}
export const getEmployeeCompaniesData = async (activeUserId: number, setIsFetching: SetIsFetchingType): Promise<EmployeeCompany[]> => {
  if(!activeUserId) return []
  try {
    setIsFetching(true);
    const { employee_companies } = await getEmployeeCompanies(activeUserId);
    setIsFetching(false);
    return employee_companies
  } catch (e) {
    setIsFetching(false);
    return []
  }
}