import React, {useEffect, useMemo, useState} from "react";
import {useHttp} from "../../utils/hooks/http.hook";
import {CustomTreeView} from "../../components/blocks/CustomTreeView";
import {NotFound} from "../../components/text";
import styles from "./manage-access.module.scss";
import {useCustomSnackbar} from "../../utils/hooks/useCustomSnackbar";
import {CButton} from "../../components/buttons";
import AddIcon from "@material-ui/icons/Add";
import {closePopUp, showPopUp} from "../../redux/reducers/appReducer";
import {useDispatch} from "react-redux";
import {CustomSearch} from "../../components/form/CustomSearch";
import {getTreeExpandedIds, searchTreeItems} from "../../utils";
import {MODALS_NAMES} from "../../constants";
import {
  getGrantData,
  getSelectChangeRequestData,
} from "./services";
import {ActiveType, GetGrantDataResponse, GetGrantsRes, Grant, GrantAddBody, SelectedObjType} from "./types";
import {ConfirmModalData} from "../../components/popup/types";

type ManageAccessRightsType = {
  selectedObj: SelectedObjType,
  onItemLabelClick: (n: ActiveType) => void,
  activeItemId: number
}
export const ManageAccessRights = ({
                                     selectedObj,
                                     onItemLabelClick,
                                     activeItemId
                                   }: ManageAccessRightsType) => {
  const dispatch = useDispatch();
  const {loading, request} = useHttp();
  const {addDefaultSnack} = useCustomSnackbar();
  const [data, setData] = useState<Grant[]>([]);
  const [expandedTree, setExpandedTree] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const getGrant = async () => {
      const res = await getGrantData(selectedObj, setIsFetching)
      setData(res);
    };
    if (!!selectedObj?.id) getGrant();
  }, [selectedObj]);

  const successRequest = () => {
    addDefaultSnack("Успешно");
    dispatch(closePopUp());
  }

  const onSelectChange = async (e, grant_id, addBody = null) => {
    const {endpoint, body} = getSelectChangeRequestData(e, selectedObj, addBody, grant_id)
    const res = await request<GetGrantDataResponse>(endpoint, "post", body);
    if (res?.status) {
      setData(res?.employee_grant || res?.group_grant);
      if (addBody) successRequest()
    }
  };

  const addNewRight = async (body = {}) => {
    if (loading) return;
    const res = await request<GetGrantsRes>(
      "core/grants/add/",
      "post",
      body,
      {},
      {isModal: true}
    );
    if (res?.status) {
      setData(res?.grants);
      successRequest()
    }
  };

  const onDeleteClick = (grant_id: number) => {
    dispatch(
      showPopUp( MODALS_NAMES.confirmModal, {onSubmit: () => deleteItem(grant_id)} as ConfirmModalData)
    );
  };

  const deleteItem = async (grant_id: number) => {
    const type = selectedObj?.type === "roles" ? "group" : "employee";
    const res = await request<GetGrantsRes>(
      `core/grants/${grant_id}/delete/${selectedObj?.id}/${type}/`,
      "delete",
      {},
      {},
      {isModal: true}
    );
    if (res?.status) {
      setData(res?.grants);
      successRequest()
    }
  };

  const onTimerClick = async (grant_id: number) => {
    const event = {target: {checked: true}};
    dispatch(
      showPopUp( MODALS_NAMES.giveTemporarilyAccess, {
        onSubmit: body => onSelectChange(event, grant_id, body),
        close: true
      })
    );
  };

  const onAddClick = async (grant_pid) => {
    dispatch(
      showPopUp( MODALS_NAMES.addGrantItem, {
        body: {grant_pid},
        onSubmit: body =>
          addNewRight({
            ...body,
            grant_pid,
            type: selectedObj.type === "roles" ? "group" : "employee",
            id: selectedObj?.id
          }),
        close: true
      })
    );
  };
  const onUpdateClick = async (grantItem: Grant) => {
    dispatch(
      showPopUp( MODALS_NAMES.addGrantItem, {
        body: grantItem,
        onSubmit: body =>
          updateItemSubmit(grantItem?.grant_id, {
            ...body,
            type: selectedObj.type === "roles" ? "group" : "employee",
            id: selectedObj?.id
          }),
        close: true
      })
    );
  };

  const updateItemSubmit = async (grant_id: number, body: GrantAddBody) => {
    const res = await request<GetGrantsRes>(
      `core/grants/${grant_id}/update/`,
      "put",
      body,
      {},
      {isModal: true}
    );
    if (res?.status) {
      setData(res?.grants);
      successRequest()
    }
  };

  const handleSearchChange = e => {
    if (!e.target?.value) {
      setSearchValue("");
      setExpandedTree([]);
    }
    setSearchValue(e.target?.value);
  };

  const computedData = useMemo(() => {
    if (!searchValue) return data;
    const search = searchValue?.toLowerCase();
    const filtered = searchTreeItems(data, search, "sub_grants", [
      "grant_name",
      "grant_code"
    ]);
    const ids = getTreeExpandedIds(filtered, "sub_grants", "grant_id");
    setExpandedTree(ids);
    return filtered;
  }, [data, searchValue]);

  const onLabelClick = (grant_id: number) => {
    grant_id && onItemLabelClick({grant_id});
  };

  return (
    <div>
      <h2>Права</h2>
      <CButton className={styles.manage__menu_btn} onClick={() => onAddClick("")}>
        Добавить <AddIcon/>
      </CButton>
      <div className={styles.manage__search_wrapper}>
        <CustomSearch handleChange={handleSearchChange} value={searchValue}/>
      </div>
      <div className={styles.manage__right__content}>
        {!!computedData?.length || isFetching ? (
          <CustomTreeView
            showTimer={selectedObj?.type === "users"}
            data={computedData}
            expandedTree={expandedTree}
            isFetching={isFetching}
            updateItem={onUpdateClick}
            onTimerClick={onTimerClick}
            deleteItem={onDeleteClick}
            addItem={onAddClick}
            valueOption={"grant_id"}
            subMenuOption={"sub_grants"}
            labelOption={"grant_name"}
            onSelectChange={onSelectChange}
            onLabelClick={onLabelClick}
            activeItemId={activeItemId}
          />
        ) : (
          <NotFound/>
        )}
      </div>
    </div>
  );
};
