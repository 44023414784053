import React from "react";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import {useHttp} from "../../utils/hooks/http.hook";
import {CButton} from "../../components/buttons";
import {useNavigate} from "react-router";
import {Controller, useForm} from "react-hook-form";
import {InputWarning} from "../../components/form/warnings";
import {getFingerKey} from "../../utils";
import {useCustomSnackbar} from "../../utils/hooks/useCustomSnackbar";
import {Link} from "react-router-dom";
import {PAGES} from "../../constants";
import {useStyles} from "../login";
import logo from "../../assets/images/logo_full.svg";
import {ForgetPassBodyType} from "../login/types";

export const ForgetPasswordPage = () => {
  const classes = useStyles();
  const history = useNavigate();
  const {
    control,
    formState: {errors},
    handleSubmit
  } = useForm();
  const {request, loading} = useHttp();
  const {addDefaultSnack} = useCustomSnackbar();

  const onSubmit = async (body: ForgetPassBodyType) => {
    body.finger = getFingerKey()
    const res = await request(
      `/auth/reset_password/`,
      "post",
      body,
      {},
      {isTokenRequired: false}
    );
    if (res) {
      addDefaultSnack("Новый пароль отправлен на почту")
      history(PAGES.login);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      style={{backgroundColor: "#fff", textAlign: "center"}}
    >
      <div className={classes.paper}>
        <div style={{width: 269, marginBottom: 50}}>
          <img alt="" src={logo}/>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <div>
            <Controller
              control={control}
              name="login"
              render={({field: {onChange, value}}) => (
                <TextField
                  margin="normal"
                  fullWidth
                  type="email"
                  value={value}
                  id="email"
                  label="Email"
                  autoComplete="email"
                  style={{color: "#818181", fontSize: 18}}
                  onChange={onChange}
                />
              )}
              rules={{required: true}}
              defaultValue={""}
            />
            {errors.login && <InputWarning>Это поле обязательно</InputWarning>}
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <CButton
              type="submit"
              loading={loading}
              disabled={loading}
              className={classes.submit}
              onClick={onSubmit}
            >
              Сбросить пароль
            </CButton>
            <p className={classes.link}>
              <Link to = {PAGES.login} >Войти</Link>
            </p>
          </div>
        </form>
      </div>
    </Container>
  );
};
