import {getAccessList} from "../api";
import {SetIsFetchingType} from "../../../types";
import {ReportGrant, ReportMenu} from "./types";

export const getReportAccess = async (setIsFetching: SetIsFetchingType): Promise<{menu: ReportMenu[], grant: ReportGrant[]}> => {
  try {
    setIsFetching(true);
    const {menu, grant} = await getAccessList();
    setIsFetching(false);
    return {menu, grant}
  } catch (e) {
    setIsFetching(false);
    return {menu: [], grant: []}
  }
}