import React, {useCallback, useEffect, useState} from "react";
import styles from "../manage-access.module.scss";
import {CustomTabPanel, CustomTabs} from "../../../components/blocks/CustomTabs";
import {SelectRoles} from "./SelectRoles";
import {SelectUser} from "./SelectUser";
import {getActiveItemData, getRolesData} from "./services";
import {getEmployeesData} from "../../userSettings/services";
import {Employee, Role} from "../../../types";
import {ActiveType, SelectedObjType} from "../types";

type ManageAccessSelectType = {
  selectedObj: SelectedObjType
  setSelectedObj: (n: SelectedObjType) => void
  resetActiveItemId: () => void
  activeItem: ActiveType
  resetSelectedObj: () => void
}

export const ManageAccessSelect = ({
                                     selectedObj,
                                     setSelectedObj,
                                     resetSelectedObj,
                                     resetActiveItemId,
                                     activeItem
                                   }: ManageAccessSelectType) => {
  const [activeTabId, setActiveTabId] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState<{roles: Role[], users: Employee[]}>({roles: null, users: null});

  const getUsers = useCallback(async () => {
    const users = await getEmployeesData({}, setIsFetching);
    setData(prev => ({...prev, users}))
  }, []);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const getRoles = useCallback(async () => {
    const roles = await getRolesData(setIsFetching);
    setData(prev => ({...prev, roles}))
  }, []);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  const handleTabChange = (e, id) => {
    setActiveTabId(id);
  };
  const getActiveItem = useCallback(async () => {
    const {roles, users} = await getActiveItemData(activeItem, setIsFetching);
    setData({users, roles});
  }, [activeItem]);

  useEffect(() => {
    getActiveItem();
  }, [getActiveItem]);

  const handleChangeRolesData = useCallback((roles: Role[]) => setData(prev => ({...prev, roles})), [])

  const resetData = async () => {
    if (!activeItem?.grant_id && !activeItem?.menu_id) return;
    resetActiveItemId();
    await getRoles();
    await getUsers();
  };
  return (
    <div className={styles.manage__select}>
      <CustomTabs
        disabled={!activeTabId}
        onChange={handleTabChange}
        value={activeTabId}
        tabs={tabs}
      />
      <div className={styles.manage__select_content}>
        <CustomTabPanel value={activeTabId} index={0}>
          <SelectRoles isFetching={isFetching} setSelectedObj={setSelectedObj} selectedObj={selectedObj}
                       resetData={resetData} resetSelectedObj={resetSelectedObj} data={data.roles}  handleChangeRolesData={handleChangeRolesData} />
        </CustomTabPanel>
        <CustomTabPanel value={activeTabId} index={1}>
          <SelectUser selectedObj={selectedObj} isFetching={isFetching} setSelectedObj={setSelectedObj}
                      data={data.users} resetData={resetData}
          />
        </CustomTabPanel>
      </div>
    </div>
  );
};
const tabs = [
  {label: "Роли", key: "roles"},
  {label: "Сотрудники", key: "users"}
];
