import React, {useEffect, useState} from "react";
import {useHttp} from "../../../utils/hooks/http.hook";
import styles from "../userSettings.module.scss";
import {UserInfoContent} from "./Content";
import {EmployeeModule} from "../../../types";
import {getModulesData} from "./services";
import {GetEmployeeModulesType} from "./types";

type UserModulesType = {
  activeUserId: number
}
export const UserModules = ({ activeUserId }: UserModulesType) => {
  const { request, loading } = useHttp();
  const [modules, setModules] = useState<EmployeeModule[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const employee_modules = await getModulesData(activeUserId, setIsFetching);
      setModules(employee_modules);
    };
    getData();
  }, [activeUserId]);

  const handleCheckChange = async module_id => {
    if (loading) return;
    const res = await request<GetEmployeeModulesType>(
      `core/employees/employee-modules/choose/`,
      "post",
      { module_id, employee_id: activeUserId }
    );
    if (res?.status) {
      setModules(res?.employee_modules);
    }
  };

  const handleCheckGrantChange = async module_id => {
    if (loading) return;
    const res = await request<GetEmployeeModulesType>(
      `/core/employees/employee-modules/give-grant/`,
      "post",
      { module_id, employee_id: activeUserId }
    );
    if (res?.status) {
      setModules(res?.employee_modules);
    }
  };

  return (
    <div>
      <div className={styles.settings__info__content}>
        <UserInfoContent
          data={modules} withGrant
          loading={loading}
          isFetching={isFetching}
          handleCheckChange={handleCheckChange}
          handleCheckGrantChange={handleCheckGrantChange}
          idKey={"module_id"}
          labelKey={"module_name"}
        />
      </div>
    </div>
  );
};
