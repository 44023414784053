import React, {useCallback, useState} from 'react'
import {CompareDataOneCFilters} from "./filters";
import {GetReceiptParams, Receipt} from "./types";
import {getDataWithOneC} from "./services";
import {CustomTable, CustomTableCell, CustomTableRow} from "../../components/blocks/CustomTable/CustomTable";
import styles from "./compareOneC.module.scss";
import clsx from "clsx";
import {numberWithCommas} from "../../utils";

export const CompareDataOneCContent = () => {
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState<Receipt[]>([])
  const [filters, setFilters] = useState<GetReceiptParams>({
    provider_bin: "",
    date_begin: "",
    date_end: "",
  })

  const getData = useCallback(async () => {
    const res = await getDataWithOneC(filters, setIsFetching)
    if(!res) return
    setData(res)
  }, [filters])

  const handleFiltersChange = async (name, value) => {
    setFilters(prev => ({...prev, [name]: value}));
  };

  return <div className={styles.compare}>
    <CompareDataOneCFilters filters={filters} handleFiltersChange={handleFiltersChange} loading={isFetching} getData={getData} />
    <CustomTable
      stickyHeader
      headItems={headItems}
      isEmpty={!data?.length}
      isFetching={isFetching}
    >
      {data?.map((item, i) => {
        return (
          <CustomTableRow
            key={i}
            className={clsx({[styles.different]: !!item.diff})}
          >
            <CustomTableCell align="left">
              {item.invoice_guid || ""}
            </CustomTableCell>
            <CustomTableCell align="center">
              {item.invoice_date || ""}
            </CustomTableCell>
            <CustomTableCell align="left">
              {item.org_bin || ""}
            </CustomTableCell>
            <CustomTableCell align="left">
              {item.contragent_bin || ""}
            </CustomTableCell>
            <CustomTableCell align="right">
              {numberWithCommas(item.invoice_sum) || ""}
            </CustomTableCell>
            <CustomTableCell align="left">
              {item.guid_sr || ""}
            </CustomTableCell>
            <CustomTableCell align="right">
              {numberWithCommas(item.invoice_sum_sr) || ""}
            </CustomTableCell>
            <CustomTableCell align="left">
              {item.org_bin_sr || ""}
            </CustomTableCell>
            <CustomTableCell align="left">
              {item.contragent_bin_sr || ""}
            </CustomTableCell>
            <CustomTableCell align="left">
              {item.diff || ""}
            </CustomTableCell>
          </CustomTableRow>
        );
      })}
    </CustomTable>
  </div>
}

const headItems = [
  {
    label: "invoice_guid",
    align: 'center',
  },
  {
    label: "invoice_date",
    align: 'center',
  },
  {
    label: "org_bin",
    align: 'left',
  },
  {
    label: "contragent_bin",
    align: 'left',
  },
  {
    label: "invoice_sum",
    align: 'right',
  },
  {
    label: "guid_sr",
    align: 'center',
  },
  {
    label: "invoice_sum_sr",
    align: 'right',
  },
  {
    label: "org_bin_sr",
    align: 'left',
  },
  {
    label: "contragent_bin_sr",
    align: 'center',
  },
  {
    label: "Разница",
    align: 'left',
  },
]