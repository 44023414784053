import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import {useDispatch, useSelector} from "react-redux";
import {IconButton, InputAdornment} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {useHttp} from "../../utils/hooks/http.hook";
import {CButton} from "../../components/buttons";
import {Navigate, useNavigate} from "react-router";
import {Controller, useForm} from "react-hook-form";
import {InputWarning} from "../../components/form/warnings";
import logo from "../../assets/images/logo_full.svg";
import {appState, setAuth, setUserData} from "../../redux/reducers/appReducer";
import {getFingerKey} from "../../utils";
import SendIcon from "@material-ui/icons/Send";
import {useCustomSnackbar} from "../../utils/hooks/useCustomSnackbar";
import {Link} from "react-router-dom";
import {PAGES} from "../../constants";
import cookiesService from "../../services/cookiesService";
import {sendCodeData} from "./services";
import {LoginBodyType, LoginRes} from "./types";

const cookiesServiceInstance = cookiesService.getService();
export const LogInPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();
  const {
    control,
    formState: {errors}, getValues,
    handleSubmit
  } = useForm();
  const {request, loading} = useHttp();
  const {addDefaultSnack} = useCustomSnackbar();

  const [showCodeInput, setShowCodeInput] = useState(false)
  const [codeLoading, setCodeLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const {isUserAuthenticated} = useSelector(appState);

  const onSubmit = async body => {
    body.finger = getFingerKey()
    try {
      const res = await request<LoginRes>(
        `/auth/login/`,
        "post",
        body,
        {},
        {isTokenRequired: false, throwErrorAll: true}
      );
      if (res) {
        dispatch(setUserData(res.user));
        dispatch(setAuth(true));
        cookiesServiceInstance.setLoginData(res);
        history("/");
      }
    } catch (e) {
      const error = e?.response?.data;
      if (error?.is_device_exist === false) {
        setShowCodeInput(true)
      }
    }
  };

  const sendCode = async () => {
    const body: LoginBodyType = getValues()
    await sendCodeData(body, setCodeLoading, addDefaultSnack)
  }

  if (isUserAuthenticated) return <Navigate to={PAGES.main} replace={true}/>;
  return (
    <Container
      component="main"
      maxWidth="xs"
      style={{backgroundColor: "#fff", textAlign: "center"}}
    >
      <div className={classes.paper}>
        <div style={{width: 269, marginBottom: 50}}>
          <img alt="" src={logo}/>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <div>
            <Controller
              control={control}
              name="login"
              render={({field: {onChange, value}}) => (
                <TextField
                  margin="normal"
                  fullWidth
                  type="email"
                  value={value}
                  id="email"
                  label="Email"
                  autoComplete="email"
                  style={{color: "#818181", fontSize: 18}}
                  onChange={onChange}
                />
              )}
              rules={{required: true}}
              defaultValue={""}
            />
            {errors.login && <InputWarning>Это поле обязательно</InputWarning>}
          </div>
          <div>
            <Controller
              control={control}
              name="password"
              render={({field: {onChange, value}}) => (
                <TextField
                  margin="normal"
                  fullWidth
                  value={value}
                  id="password"
                  label="Пароль"
                  type={showPassword ? "text" : "password"}
                  style={{color: "#818181", fontSize: 18}}
                  onChange={onChange}
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              )}
              rules={{required: true}}
              defaultValue={""}
            />
            {errors.password && (
              <InputWarning>Это поле обязательно</InputWarning>
            )}
          </div>
          {
            showCodeInput && <div>
              <div className={classes.code__wrapper}>
                <div>
                  <Controller
                    control={control}
                    name="code_key"
                    render={({field: {onChange, value}}) => (
                      <TextField
                        margin="normal"
                        fullWidth
                        type="number"
                        value={value}
                        id="email"
                        label="Код"
                        style={{color: "#818181", fontSize: 18}}
                        onChange={onChange}
                      />
                    )}
                    rules={{required: true}}
                    defaultValue={""}
                  />
                </div>
                <CButton
                  type="button" loading={codeLoading} onClick={sendCode} className={classes.code__btn}>Получить
                  код <SendIcon className={classes.code__btn_icon}/></CButton>
              </div>
              {errors.code_key && <InputWarning nowrap>Это поле обязательно</InputWarning>}
            </div>
          }
          <div className="d-flex flex-column align-items-center justify-content-center">
            <CButton
              type="submit"
              loading={loading}
              disabled={loading}
              className={classes.submit}
              onClick={onSubmit}
            >
              Войти
            </CButton>
            <p className={classes.link}>
              <Link to={PAGES.forgetPassword} >Забыли пароль?</Link>
            </p>
          </div>
        </form>
      </div>
    </Container>
  );
};

export const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    backgroundColor: "#fff",
    justifyContent: "center"
  },
  form: {
    width: 269, // Fix IE 11 issue.
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(1),
    height: "auto !important"
  },
  submit: {
    margin: "30px 0",
    background: "linear-gradient(104.82deg, #0C4B86 -17.14%, #E42734 83.14%)",
    height: 50,
    fontSize: 18,
    borderRadius: "0.5rem",
    width: "100%"
  },
  code__wrapper: {
    display: "grid",
    alignItems: "flex-end",
    gridTemplateColumns: "5fr 4fr",
    gap: "30px"
  },
  code__btn: {
    background: "#2582d1 !important",
    color: "#fff !important",
    fontSize: "12px !important",
    display: "flex",
    alignItems: "center",
    padding: "5px",
    height: 'auto',
    width: "auto",
    marginBottom: "8px !important"
  },
  code__btn_icon: {
    fontSize: "15px !important",
    marginLeft: 5
  },
  link: {
    textAlign: 'center',
    '& a': {
      color: '#0c4388',
      fontSize: 16,
      '&:hover': {
        textDecoration: 'none'
      }
    }
  }
}));
