import React from "react";
import styles from "./textModal.module.scss";
import {TextModalProps} from "../types";

export const TextModal = ({data}: TextModalProps) => {
  return (
    <div className={styles.modal}>
      <h1 className={styles.modal__title}>{data?.title || ""}</h1>
      {!!data?.addInfo && (
        <p className={styles.modal__addInfo}>{data?.addInfo}</p>
      )}
      <p
        className={styles.modal__description}
      >
        {data?.description}
        {!!data?.value && (
          <span
            className={data?.copy ? styles.modal__copy : ""}
          >
            <span>{data?.value}</span>
          </span>
        )}
      </p>
    </div>
  );
};
