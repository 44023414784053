import React, {useEffect, useState} from "react";
import {CustomSearch} from "../../../components/form/CustomSearch";
import {AutoSuggest} from "../../../components/form/AutoSuggest";
import clsx from "clsx";
import styles from "../userSettings.module.scss";
import {useSelector} from "react-redux";
import {appState} from "../../../redux/reducers/appReducer";
import {FiltersType} from "../UserSettings";
import {Position} from "../../../types";
import {getPositions} from "../../../api/services";

type UsersFiltersType = {
  show: boolean
  filters: FiltersType
  handleFiltersChange: (n: string, b: string) => void
}
export const UsersFilters = ({filters, handleFiltersChange, show}: UsersFiltersType) => {
  const {roles, moduleContragents: contragents} = useSelector(appState);
  const [positions, setPositions] = useState<Position[]>([])

  useEffect(() => {
    const getData = async () => {
      const res = await getPositions()
      if (!res) return
      setPositions(res)
    }
    getData()
  }, [])

  return <div className={clsx(styles.settings__header_filters, {[styles.settings__header_filtersHide]: !show})}>
      <h2 className={styles.settings__header_filtersTitle}>Фильтры</h2>
      <div className={styles.settings__header_filtersContent}>
        <CustomSearch
          className={styles.settings__header_search}
          value={filters.search}
          handleChange={e =>
            handleFiltersChange("search", e?.target?.value)
          }
        />
      <div className={styles.settings__header_filtersSmall}>
        <AutoSuggest
          list={activeUsersFilter}
          showKey={"label"}
          valueKey={"value"}
          value={filters?.is_active}
          onChange={e =>
            handleFiltersChange("is_active", e.target.value || "all")
          }
          placeholder="Активные"
          label="Активные"
          defaultValue={""}
        />
      </div>
      <div className={styles.settings__header_filtersActive}>
        <AutoSuggest
          list={roles}
          showKey={"group_name"}
          valueKey={"group_id"}
          value={filters?.group_id}
          onChange={e => handleFiltersChange("group_id", e.target.value)}
          placeholder="Роль"
          label="Роль"
          defaultValue={""}
        />
      </div>
      <div className={styles.settings__header_filtersActive}>
        <AutoSuggest
          list={contragents}
          showKey={"contragent_name"}
          valueKey={"contragent_id"}
          value={filters?.contragent_id}
          onChange={e =>
            handleFiltersChange("contragent_id", e.target.value)
          }
          placeholder="Контрагент"
          label="Контрагент"
          renderOption={props => {
            return (
              <div className={clsx(styles.settings__contragent, {[styles.settings__contragent_sub]: !!props?.is_sub})}>
                {props?.contragent_name}
              </div>
            );
          }}
          defaultValue={""}
        />
      </div>
        <div className={styles.settings__header_filtersActive}>
          <AutoSuggest
            list={positions}
            showKey={"position_name"}
            valueKey={"position_id"}
            value={filters?.position_id}
            onChange={e => handleFiltersChange("position_id", e.target.value)}
            placeholder="Должность"
            label="Должность"
            defaultValue={""}
          />
        </div>
    </div>
  </div>
}

export const activeValues = {
  all: "all",
  active: "active",
  inactive: "inactive",
} as const
type ActiveValuesType = typeof activeValues
export type ActiveValuesKeysType = keyof ActiveValuesType

const activeUsersFilter = [
  {label: "Все", value: activeValues.all},
  {label: "Активные", value: activeValues.active},
  {label: "Не активные", value: activeValues.inactive}
];