import React, {useEffect} from "react";
import {Provider, useSelector} from "react-redux";
import {SnackbarProvider} from "notistack";
import CssBaseline from "@material-ui/core/CssBaseline";
import MuiTheme from "./theme";
import PopUp from "./components/popup";
import {ThemeProvider} from "@material-ui/core/styles";
import {HashRouter as Router} from "react-router-dom";
import {Content} from "./components/Content/Content";
import {Route, Routes as Switch, useNavigate} from "react-router";
import {PAGES} from "./constants";
import {store, useAppDispatch} from "./redux";
import {SnackbarUtilsConfigurator} from "./components/helpers/snackbar";
import {LogInPage} from "./pages/login";
import {LoaderData} from "./components/LoaderData";
import {initialize} from "./redux/actions/app-actions";
import {appState} from "./redux/reducers/appReducer";
import {ForgetPasswordPage} from "./pages/forgetPassword";
import {DispatchComponent} from "./api/helpers";
import "./assets/_base.scss";

export const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <DispatchComponent/>
        <AppWrapped/>
      </Router>
    </Provider>
  );
};

const AppWrapped = () => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const {init} = useSelector(appState);

  useEffect(() => {
    dispatch(initialize(history));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!init) return <LoaderData/>;
  return (
    <SnackbarProvider autoHideDuration={3000} maxSnack={3}>
      <SnackbarUtilsConfigurator/>
      <ThemeProvider theme={MuiTheme}>
        <Switch>
          <Route path={PAGES.login} element={<LogInPage/>}/>
          <Route path={PAGES.forgetPassword} element={<ForgetPasswordPage/>}/>
          <Route path="*" element={<Content/>}/>
        </Switch>
        <PopUp/>
        <CssBaseline/>
      </ThemeProvider>
    </SnackbarProvider>
  );
};
