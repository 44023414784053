import instance from "../../../api";
import {
  GetChosenAccessRes,
  GetEmployeeGrantsResponse,
  GetEmployeeMenusResponse, GetGrantsRes,
  GetGroupGrantsResponse,
  GetGroupMenusResponse, GetGroupsResponse, GetMenusRes, GetModulesRes
} from "../types";
import {GetAccessListResponse} from "../ReportAccessList/types";

export const getEmployeeMenu = async (body): Promise<GetEmployeeMenusResponse> => {
  return await instance(true)
    .post("core/employees/employee-menus/", body)
    .then(res => res?.data);
};

export const getGroupMenu = async (body): Promise<GetGroupMenusResponse> => {
  return await instance(true)
    .post("core/groups/group-menus/", body)
    .then(res => res?.data);
};

export const getGroupMenuItems = async (): Promise<GetMenusRes> => {
  return await instance(true)
    .get("core/menus/")
    .then(res => res?.data);
};

export const getGroupGrantItems = async (): Promise<GetGrantsRes> => {
  return await instance(true)
    .get("core/grants/")
    .then(res => res?.data);
};

export const getEmployeeGrant = async (body): Promise<GetEmployeeGrantsResponse> => {
  return await instance(true)
    .post("core/employees/employee-grants/", body)
    .then(res => res?.data);
};

export const getGroupGrant = async (body):Promise<GetGroupGrantsResponse> => {
  return await instance(true)
    .post("core/groups/group-grants/", body)
    .then(res => res?.data);
};

export const getChosenItemData = async (body): Promise<GetChosenAccessRes> => {
  return await instance(true)
    .post("core/commons/chosen/", body)
    .then(res => res?.data);
};

export const getAccessList = async (): Promise<GetAccessListResponse> => {
  return await instance(true)
    .get("core/commons/grant-menu/employee-list/date/")
    .then(res => res?.data);
};

export const getEmployeeRoles = async (params = {}): Promise<GetGroupsResponse> => {
  return await instance(true)
    .get("/core/groups/", { params })
    .then(res => res?.data);
};

export const getModules = async (): Promise<GetModulesRes> => {
  return await instance(true)
    .get("/core/commons/modules/")
    .then(res => res?.data);
};

export const updateUserModuleId = async module_id => {
  return await instance(true)
    .post("/core/commons/modules/", { module_id })
    .then(res => res?.data);
};
export const updateUserCompanyId = async company_id => {
  return await instance(true)
    .post("/core/companies/change/", { company_id })
    .then(res => res?.data);
};
