import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import styles from "./addMenu.module.scss";
import {InputWarning} from "../../form/warnings";
import {CButton} from "../../buttons";
import {useSelector} from "react-redux";
import CustomInput from "../../form/CustomInput";
import {Chart, Chat, Document, Home, Paper, Search, Setting, Wallet} from "react-iconly";
import {AutoSuggest} from "../../form/AutoSuggest";
import {InputSumMask} from "../../form/InputSumMask";
import {getGroupMenuItems} from "../../../pages/ManageAccess/api";
import {appState} from "../../../redux/reducers/appReducer";
import {Menu} from "../../../pages/ManageAccess/types";
import {AddMenuItemProps} from "./types";

export const AddMenuItem = ({ data }: AddMenuItemProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();
  const { modalLoading } = useSelector(appState);
  const { onSubmit: onAddSubmit, body, menu_pid } = data;
  const [menu, setMenu] = useState<Menu[]>(null);

  useEffect(() => {
    getGroupMenuItems().then(({menu}) => {
      setMenu(menu);
    });
  }, []);

  const onSubmit = async (body: Menu) => {
    await onAddSubmit(body);
  };
  return (
    <div>
      <h2>{`${body ? "Изменение" : "Добавление"} нового элемента меню`}</h2>
      <form className={styles.menu__form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.menu__form_item}>
          <Controller
            name="menu_name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomInput
                label="Название"
                placeholder="Введите название"
                value={value}
                onChange={onChange}
                fullWidth
              />
            )}
            defaultValue={body?.menu_name || ""}
            rules={{
              required: true
            }}
          />
          {errors.menu_name && (
            <InputWarning>Это поле обязательно</InputWarning>
          )}
        </div>
        <div className={styles.menu__form_item}>
          <Controller
            name="menu_action"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomInput
                label="Menu action"
                placeholder="Введите menu action"
                value={value}
                onChange={onChange}
                fullWidth
              />
            )}
            defaultValue={body?.menu_action || ""}
          />
          {errors.menu_action && (
            <InputWarning>Это поле обязательно</InputWarning>
          )}
        </div>
        <div className={styles.menu__form_item}>
          <Controller
            name="icon"
            control={control}
            render={({ field }) => {
              const { onChange, value } = field;
              return (
                <AutoSuggest
                  withMargin
                  list={menu_icons}
                  showKey={"name"}
                  valueKey={"name"}
                  placeholder="Выберите иконку"
                  label="Выберите иконку"
                  value={value}
                  onChange={onChange}
                  renderOption={props => {
                    return (
                      <div className={styles.menu__form_icons}>
                        {props?.render}
                        {props?.name}
                      </div>
                    );
                  }}
                />
              );
            }}
            defaultValue={body?.icon || ""}
          />
          {errors.icon && <InputWarning>Это поле обязательно</InputWarning>}
        </div>
        <div className={styles.menu__form_item}>
          <Controller
            name="order_num"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputSumMask
                label="Order Num"
                placeholder="Введите Order Num"
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={body?.order_num || ""}
          />
        </div>
        {menu && (
          <div className={styles.menu__form_item}>
            <Controller
              name="menu_pid"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <AutoSuggest
                    withMargin
                    list={menu}
                    showKey={"menu_name"}
                    valueKey={"menu_id"}
                    placeholder="Выберите родителя"
                    label="Выберите родителя"
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
              defaultValue={body?.menu_pid || menu_pid || ""}
            />
          </div>
        )}
        <div className={styles.menu__form_btn}>
          <CButton loading={modalLoading} disabled={modalLoading}>
            Сохранить
          </CButton>
        </div>
      </form>
    </div>
  );
};

export const menu_icons = [
  { name: "Home", render: <Home set="light" /> },
  { name: "Wallet", render: <Wallet set="light" /> },
  { name: "Chart", render: <Chart set="light" /> },
  { name: "Document", render: <Document set="light" /> },
  { name: "Chat", render: <Chat set="light" /> },
  { name: "Paper", render: <Paper set="light" /> },
  { name: "Search", render: <Search set="light" /> },
  { name: "Setting", render: <Setting set="light" /> }
];
