import React, {useState} from "react";
import {ManageAccessSelect} from "./ManageAccessSelect";
import {NotFound} from "../../components/text";
import {ManageAccessMenu} from "./ManageAccessMenu";
import {ManageAccessRights} from "./ManageAccessRights";
import styles from "./manage-access.module.scss";
import {ActiveType, SelectedObjType} from "./types";
import {CustomTabPanel, CustomTabs} from "../../components/blocks/CustomTabs";

const defaultObj = {
  type: null,
  id: null,
  name: "",
} as SelectedObjType;

const activeTypeDefault = {
  menu_id: null, grant_id: null
}

export const ManageAccess = () => {
  const [selectedObj, setSelectedObj] = useState<SelectedObjType>(defaultObj);
  const [activeItem, setActiveItem] = useState<ActiveType>(activeTypeDefault);
  const [activeTabId, setActiveTabId] = useState(0);

  const handleTabChange = (e, id: number) => {
    setActiveTabId(id);
  };

  const onItemLabelClick = (obj: ActiveType) => {
    const newObj = { ...activeTypeDefault, ...obj };
    setActiveItem(newObj);
  };

  const resetActiveItemId = () => {
    setActiveItem(activeTypeDefault);
  };

  const handleChangeSelectedObj = (obj: SelectedObjType) => {
    setSelectedObj(obj)
  }

  const resetSelectedObj = () => setSelectedObj(defaultObj)

  return (
    <div className={styles.manage}>
      <div className={styles.manage__content}>
        <ManageAccessSelect resetSelectedObj={resetSelectedObj}
          resetActiveItemId={resetActiveItemId}
          activeItem={activeItem}
          selectedObj={selectedObj}
          setSelectedObj={handleChangeSelectedObj}
        />

        {!!selectedObj?.id ? (
          <div>
            {!!selectedObj?.name && (
              <h1 className={styles.manage__right_title}>
                {selectedObj.type === "roles" ? "Роль" : "Сотрудник"}:{" "}
                {selectedObj?.name}
              </h1>
            )}
            <div className={styles.manage__right}>
              <CustomTabs
                disabled={!activeTabId}
                onChange={handleTabChange}
                value={activeTabId}
                tabs={tabs}
              />
              <CustomTabPanel value={activeTabId} index={0}>
                <ManageAccessMenu
                  activeItemId={activeItem?.menu_id}
                  onItemLabelClick={onItemLabelClick}
                  selectedObj={selectedObj}
                />
              </CustomTabPanel>
              <CustomTabPanel value={activeTabId} index={1}>
                <ManageAccessRights
                  activeItemId={activeItem?.grant_id}
                  onItemLabelClick={onItemLabelClick}
                  selectedObj={selectedObj}
                />
              </CustomTabPanel>
            </div>
          </div>
        ) : (
          <NotFound>Выберите роль или сотрудника</NotFound>
        )}
      </div>
    </div>
  );
};

const tabs = [
  { label: "Меню" },
  { label: "Права" },
];
