import React, {useEffect, useMemo, useState} from "react";
import {CustomTreeView} from "../../components/blocks/CustomTreeView";
import {useHttp} from "../../utils/hooks/http.hook";
import {NotFound} from "../../components/text";
import AddIcon from "@material-ui/icons/Add";
import {CButton} from "../../components/buttons";
import {useCustomSnackbar} from "../../utils/hooks/useCustomSnackbar";
import {useDispatch} from "react-redux";
import {closePopUp, showPopUp} from "../../redux/reducers/appReducer";
import styles from "./manage-access.module.scss";
import {CustomSearch} from "../../components/form/CustomSearch";
import {getTreeExpandedIds, searchTreeItems} from "../../utils";
import {MODALS_NAMES} from "../../constants";
import {getMenuData, getRequestData} from "./services";
import {ActiveType, GetMenuDataResponse, GetMenusRes, Menu, MenuAddBody, SelectedObjType} from "./types";
import {ConfirmModalData} from "../../components/popup/types";
import {AddMenuItemData} from "../../components/popup/modals/types";

type ManageAccessMenuType = {
  selectedObj: SelectedObjType,
  activeItemId: number,
  onItemLabelClick: (n: ActiveType) => void
}
export const ManageAccessMenu = ({
                                   selectedObj,
                                   activeItemId,
                                   onItemLabelClick
                                 }: ManageAccessMenuType) => {
  const dispatch = useDispatch();
  const {request} = useHttp();
  const {addDefaultSnack} = useCustomSnackbar();
  const [data, setData] = useState<Menu[]>(null);
  const [expandedTree, setExpandedTree] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isInitial, setIsInitial] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const getMenu = async () => {
      const res = await getMenuData(selectedObj, setIsFetching)
      setData(prev => {
        setIsInitial(!prev)
        return res
      });
    };
    if (!!selectedObj?.id) getMenu();
  }, [selectedObj]);

  const successRequest = (withSnackbar = true) => {
    setIsInitial(false)
    if (withSnackbar) {
      dispatch(closePopUp());
      addDefaultSnack("Успешно");
    }
  }

  const onSelectChange = async (e, menu_id: number, addBody: Menu = null) => {
    const {body, endpoint} = getRequestData(e, selectedObj, addBody, menu_id)
    const res = await request<GetMenuDataResponse>(endpoint, "post", body);
    if (res?.status) {
      setData(res?.employee_menu || res?.group_menu);
      successRequest(!!addBody)
    }
  };

  const addItem = (menu_pid?: number) => {
    dispatch(
      showPopUp(MODALS_NAMES.addMenuItem, {
        onSubmit: body =>
          sendNewItem({
            ...body,
            type: selectedObj.type === "roles" ? "group" : "employee",
            id: selectedObj?.id
          }),
        close: true,
        menu_pid
      } as AddMenuItemData)
    );
  };

  const sendNewItem = async (body: MenuAddBody) => {
    const res = await request<GetMenusRes>(
      "core/menus/add/",
      "post",
      body,
      {},
      {isModal: true}
    );
    if (res?.status) {
      setData(res?.menu);
      successRequest()
    }
  };

  const deleteItem = async (menu_id: number) => {
    const type = selectedObj?.type === "roles" ? "group" : "employee";
    const res = await request<GetMenusRes>(
      `core/menus/${menu_id}/delete/${selectedObj?.id}/${type}/`,
      "delete",
      {},
      {},
      {isModal: true}
    );
    if (res?.status) {
      setData(res?.menu);
      successRequest()
    }
  };

  const onDeleteClick = (menu_id: number) => {
    dispatch(
      showPopUp(MODALS_NAMES.confirmModal, {onSubmit: () => deleteItem(menu_id)} as ConfirmModalData)
    );
  };

  const onTimerClick = async (menu_id: number) => {
    const event = {target: {checked: true}};
    dispatch(
      showPopUp(MODALS_NAMES.giveTemporarilyAccess, {
        onSubmit: body => onSelectChange(event, menu_id, body),
        close: true
      })
    );
  };

  const updateItem = async (menuItem: Menu) => {
    dispatch(
      showPopUp(MODALS_NAMES.addMenuItem, {
        body: menuItem,
        onSubmit: body =>
          updateItemSubmit(menuItem?.menu_id, {
            ...body,
            type: selectedObj.type === "roles" ? "group" : "employee",
            id: selectedObj?.id
          }),
        close: true
      } as AddMenuItemData)
    );
  };

  const updateItemSubmit = async (menu_id: number, body: MenuAddBody) => {
    const res = await request<GetMenusRes>(
      `core/menus/${menu_id}/update/`,
      "put",
      body,
      {},
      {isModal: true}
    );
    if (res?.status) {
      setData(res?.menu);
      successRequest()
    }
  };

  const handleSearchChange = e => {
    if (!e.target?.value) {
      setSearchValue("");
      setExpandedTree([]);
    }
    setSearchValue(e.target.value);
  };

  const computedData = useMemo(() => {
    if (!searchValue) {
      if (isInitial) {
        if(data?.length === 1) {
          const ids = getTreeExpandedIds(data, "sub_menus", "menu_id", 1);
          setExpandedTree(ids);
        }
      }
      return data;
    }
    const search = searchValue?.toLowerCase();
    const filtered = searchTreeItems(data, search, "sub_menus", ["menu_name"]);
    const ids = getTreeExpandedIds(filtered, "sub_menus", "menu_id");
    setExpandedTree(ids);
    return filtered;
  }, [data, searchValue, isInitial]);

  const onLabelClick = (menu_id: number) => {
    menu_id && onItemLabelClick({menu_id});
  };

  return (
    <div>
      <h2>Меню</h2>
      <CButton className={styles.manage__menu_btn} onClick={() => addItem()}>
        Добавить <AddIcon/>
      </CButton>
      <div className={styles.manage__search_wrapper}>
        <CustomSearch handleChange={handleSearchChange} value={searchValue}/>
      </div>
      <div className={styles.manage__right__content}>
        {!!computedData?.length || isFetching ? (
          <CustomTreeView
            expandedTree={expandedTree}
            isFetching={isFetching}
            addItem={addItem}
            updateItem={updateItem}
            deleteItem={onDeleteClick}
            data={computedData}
            showTimer={selectedObj?.type === "users"}
            onTimerClick={onTimerClick}
            onSelectChange={onSelectChange}
            activeItemId={activeItemId}
            onLabelClick={onLabelClick}
          />
        ) : (
          <NotFound/>
        )}
      </div>
    </div>
  );
};
