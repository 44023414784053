import {getMenuList} from "./api";
import {Menu} from "../../../pages/ManageAccess/types";

export const getMenuData = async (setIsFetching): Promise<Menu[]> => {
  setIsFetching(true);
  return await getMenuList()
    .then(({menu}) => {
      setIsFetching(false);
      if (menu && menu?.length === 1 && !!menu[0]) {
        return menu[0]?.sub_menus
      }
      return menu || []
    })
    .catch(() => {
      setIsFetching(false);
      return []
    });
}