import {getFingerKey} from "../../utils";
import {sendCode} from "./api";
import {LoginBodyType} from "./types";
import {SetIsFetchingType} from "../../types";

export const sendCodeData = async (body:LoginBodyType, setCodeLoading: SetIsFetchingType, addDefaultSnack) => {
  const params = {...body}
  delete params.code_key
  params.finger = getFingerKey()
  try {
    setCodeLoading(true)
    const res = await sendCode(params)
    setCodeLoading(false)
    if (res?.status)
      addDefaultSnack("Код был отправлен на вашу почту");
  } catch (e) {
    setCodeLoading(false)
  }
}