import React, {useMemo} from "react";
import styles from "../userSettings.module.scss";
import {Checkbox, TableCell, TableRow} from "@material-ui/core";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow,
  HeadItemText
} from "../../../components/blocks/CustomTable/CustomTable";
import {makeStyles} from "@material-ui/core/styles";

type UserInfoContent = {
  data?: any[]
  loading?: boolean
  idKey?: string
  isFetching?: boolean
  allSelected?: boolean
  noDataText?: string
  selectAll?: (b: boolean) => void
  preItem?: boolean
  withGrant?: boolean,
  handleCheckChange?: (n: number) => void
  handleCheckGrantChange?: (n: number) => void
  preHeadLabel?: string
  labelKey?: string
  preLabelKey?: string,
}
export const UserInfoContent = ({
                                  data,
                                  loading,
                                  idKey,
                                  isFetching,
                                  allSelected, noDataText,
                                  selectAll,
                                  preItem,
                                  withGrant = false,
                                  handleCheckChange,
                                  handleCheckGrantChange,
                                  preHeadLabel,
                                  labelKey,
                                  preLabelKey = "",
                                }: UserInfoContent) => {
  const classes = useStyles();
  const renderTableHeader = () => {
    if (!selectAll) return null;
    return (
      <TableRow className={classes.headRowHeader}>
        <TableCell
          align={"right"}
          colSpan={10}
          className={`${classes.headRow} ${classes.headRowCell}`}
        >
          <label htmlFor="select_all_items">
            <HeadItemText>Выбрать все</HeadItemText>
          </label>
          <Checkbox
            id={"select_all_items"}
            disabled={loading}
            checked={allSelected}
            onChange={e => selectAll(!!e?.target?.checked)}
          />
        </TableCell>
      </TableRow>
    );
  };


  const headItems = useMemo(() => {
    const items = [{label: "Название", align: "left"}, {label: "Выбрано"}]
    if (preItem) return [{label: "Название", align: "left"}, {label: preHeadLabel, align: "left"}, {label: "Выбрано"}]
    if (withGrant) items.push({label: "Выдавать"})
    return items
  }, [withGrant, preItem, preHeadLabel]);

  return (
    <div>
      <CustomTable
        stickyHeader noDataText={noDataText}
        headItems={headItems}
        isEmpty={!data?.length}
        isFetching={isFetching}
        headerRender={!!data?.length && selectAll && renderTableHeader}
      >
        {data?.map(item => {
          return (
            <CustomTableRow
              key={item[idKey]}
              className={styles.settings__info_item}
            >
              <CustomTableCell align={"left"}>{item[labelKey]}</CustomTableCell>
              {preItem && <CustomTableCell align={"left"}>{item[preLabelKey]}</CustomTableCell>}
              <CustomTableCell align={"center"}>
                <Checkbox
                  id={"is_active_user_settings"}
                  disabled={loading}
                  checked={!!item?.is_chosen}
                  onChange={() => handleCheckChange(item[idKey])}
                />
              </CustomTableCell>
              {withGrant && <CustomTableCell align={"center"}>
                <Checkbox
                  disabled={loading}
                  checked={!!item?.with_grant_option}
                  onChange={() => handleCheckGrantChange(item[idKey])}
                />
              </CustomTableCell>}
            </CustomTableRow>
          );
        })}
      </CustomTable>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  headRow: {
    backgroundColor: "#ECEEFD"
  },
  headRowHeader: {
    padding: "0"
  },
  headRowCell: {
    padding: "0 25px 0 0"
  }
}));
