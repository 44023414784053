import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import styles from "./addMenu.module.scss";
import {InputWarning} from "../../form/warnings";
import {CButton} from "../../buttons";
import {useSelector} from "react-redux";
import CustomInput from "../../form/CustomInput";
import {InputSumMask} from "../../form/InputSumMask";
import {getGroupGrantItems} from "../../../pages/ManageAccess/api";
import {AutoSuggest} from "../../form/AutoSuggest";
import {appState} from "../../../redux/reducers/appReducer";
import {Grant} from "../../../pages/ManageAccess/types";
import {AddGrantItemProps} from "./types";
import {Checkbox} from "@material-ui/core";

export const AddGrantItem = ({ data }: AddGrantItemProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();
  const { modalLoading } = useSelector(appState);
  const { onSubmit: onAddSubmit, body } = data;
  const [grants, setGrants] = useState<Grant[] | null>(null);

  useEffect(() => {
    getGroupGrantItems().then(({grants}) => {
      setGrants(grants);
    });
  }, []);

  const onSubmit = async (body: Grant) => {
    await onAddSubmit(body);
  };
  return (
    <div className={styles.menu}>
      <h2>{!!data.body?.grant_id ? 'Изменение' : 'Добавление'} права</h2>
      <form className={styles.menu__form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.menu__form_item}>
          <Controller
            name="grant_name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomInput
                label="Название"
                placeholder="Введите название"
                value={value}
                onChange={onChange}
                fullWidth
              />
            )}
            defaultValue={body?.grant_name || ""}
            rules={{
              required: true
            }}
          />
          {errors.grant_name && (
            <InputWarning>Это поле обязательно</InputWarning>
          )}
        </div>
        <div className={styles.menu__form_item}>
          <Controller
            name="grant_code"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomInput
                label="Код"
                placeholder="Введите код"
                value={value}
                onChange={onChange}
                fullWidth
              />
            )}
            defaultValue={body?.grant_code || ""}
            rules={{
              required: true
            }}
          />
          {errors.grant_code && (
            <InputWarning>Это поле обязательно</InputWarning>
          )}
        </div>
        <div className={styles.menu__form_item}>
          <Controller
            name="order_num"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputSumMask
                allowNegative
                min={-1000}
                label="Order Num"
                placeholder="Введите Order Num"
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={body?.order_num || ""}
          />
        </div>
        {grants && (
          <div className={styles.menu__form_item}>
            <Controller
              name="grant_pid"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <AutoSuggest
                    withMargin
                    list={grants}
                    showKey={"grant_name"}
                    valueKey={"grant_id"}
                    placeholder="Выберите родителя"
                    label="Выберите родителя"
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
              defaultValue={body?.grant_pid || ""}
            />
          </div>
        )}
        <div className={styles.menu__form_item}>
          <label htmlFor={"is_can_be_parent"}>Может быть родителем</label>
          <Controller
            name="is_can_be_parent"
            control={control}
            render={({field: {onChange, value}}) => (
              <Checkbox
                id={'is_can_be_parent'}
                checked={!!value}
                onChange={onChange}
              />
            )}
            defaultValue={!!body?.is_can_be_parent}
          />
        </div>
        <div className={styles.menu__form_btn}>
          <CButton loading={modalLoading} disabled={modalLoading}>
            Сохранить
          </CButton>
        </div>
      </form>
    </div>
  );
};
