import {getDataWith1C} from "./api";
import {GetReceiptParams, Receipt} from "./types";

export const getDataWithOneC = async (params: GetReceiptParams = {}, setIsFetching: (b: boolean) => void) => {
  try {
    const isAllExist = Object.keys(params).every(item => !!params[item])
    if (!isAllExist) return
    setIsFetching(true)
    const res = await getDataWith1C(params);
    const data = res?.invoice_list
    const sum: Receipt = {
      invoice_guid: "Итого",
      invoice_sum_sr: data.reduce((prev, curr) => prev + curr.invoice_sum_sr, 0),
      invoice_sum: data.reduce((prev, curr) => prev + (curr.invoice_sum || 0), 0),
      contragent_bin: "",
      contragent_bin_sr: "",
      invoice_date: "",
      org_bin: "",
      org_bin_sr: "",
      invoice_date_sr: "",
      guid_sr: "",
      diff: "",
    }
    setIsFetching(false)
    data.push(sum)
    return res?.invoice_list;
  } catch (e) {
    setIsFetching(false)
    return null
  }
}