import React from 'react'
import {IconButton, makeStyles, Popover} from "@material-ui/core";
import {MoreVert} from "@material-ui/icons";
import {useState} from "react";
import clsx from "clsx";

type CustomMoreActionsType = {
  actions: any[]
  zeroPadding?: boolean,
  value?: any
}
export const CustomMoreActions = ({actions, zeroPadding = false, value = null}: CustomMoreActionsType) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation()
    setAnchorEl(null);
  };

  return <div className={classes.itemActions}>
    <IconButton
      onClick={handleClick}
      className={clsx(classes.iconButton, {[classes.iconButtonZero]: zeroPadding})}
    >
      <MoreVert />
    </IconButton>
    <Popover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
    >
      <ul className={classes.actions_list}>
        {actions?.map((action, idx) => {
          return (
            <li
              key={idx}
              className={classes.actions_list__item}
              onClick={() => action.onClick && action.onClick(value)}
            >
              {action?.content}
            </li>
          );
        })}
      </ul>
    </Popover>
  </div>
}

const useStyles = makeStyles({
  itemActions: {
    display: "flex",
    gap: 1,
    alignItems: "center",
    position: "relative"
  },
  actions_list: {
    listStyle: "none",
    padding: "0 5px",
    display: "flex",
    flexDirection: "column",
    gap: 5
  },
  actions_list__item: {
    display: "flex",
    justifyContent: "center"
  },
  iconButton: {
    padding: 5
  },
  iconButtonZero: {
    padding: 0
  },
});