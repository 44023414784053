import {useCallback, useState} from "react";
import instance from "../../api";
import {useDispatch} from "react-redux";
import {setModalLoading} from "../../redux/reducers/appReducer";

type RequestTypes = "get" | "post" | "put" | "delete" | "patch"
type RequestOptionsType = {
  isTokenRequired?: boolean,
  throwError?: boolean,
  throwErrorAll?: boolean,
  isModal?: boolean,
  showToast?: boolean,
  isLoadingRequired?: boolean,
  params?: any,
}
export const useHttp = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async <T extends any>(url: string, method: RequestTypes = "get", body = {}, headers: any = {}, optionsProps: RequestOptionsType = {}) => {
      const options = {
        isTokenRequired: true,
        throwError: false,
        throwErrorAll: false,
        isModal: false,
        showToast: true,
        isLoadingRequired: true,
        params: {},
        ...optionsProps
      };
      options?.isLoadingRequired !== false &&
      !options?.isModal &&
      setLoading(true);
      options?.isModal &&
      options?.isLoadingRequired &&
      dispatch(setModalLoading(true));

      const throwError = options?.throwError || options?.throwErrorAll
      try {
        const response = await instance(
          options?.isTokenRequired,
          null,
          {throwError, showToast: options.showToast}
        )[method](url, body, {headers, params: options.params});

        setLoading(false);
        dispatch(setModalLoading(false));

        if (!response) new Error("Что-то пошло не так");
        const data: T = await response.data
        return data
      } catch (e) {
        setLoading(false);
        dispatch(setModalLoading(false));
        if (options?.throwError) {
          const message = e?.response?.data?.error || e?.response?.data?.message;
          throw new Error(message || "Что-то пошло не так");
        }
        if (options?.throwErrorAll)
          return Promise.reject(e)
      }
    },
    [dispatch]
  );

  return {loading, request};
};
