import React from "react";
import { Routes } from "../Routes";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { PAGES } from "../../constants";
import { MainLayout } from "../layout";
import { appState } from "../../redux/reducers/appReducer";

export const Content = () => {
  const { isUserAuthenticated } = useSelector(appState);
  if (!isUserAuthenticated) return <Navigate to={PAGES.login} replace={true} />;
  return (
    <MainLayout>
      <Routes />
    </MainLayout>
  );
};

