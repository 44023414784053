import {LOCALSTORAGEFIELDS} from "../constants";

export const getLocaleDate = (date: string): string => {
  if (!date) return "";
  try {
    const dateString = new Date(date).toLocaleDateString("ru");
    if (dateString === "Invalid Date") {
      const newDate = date?.replaceAll(".", "/");
      const dateString = new Date(newDate).toLocaleDateString("ru");
      return dateString === "Invalid Date" ? "" : dateString;
    }
    return dateString;
  } catch (e) {
    return "";
  }
};

export const getValidDate = (date: string): string => {
  if (!date) return "";
  try {
    const year = new Date(date).getFullYear().toString();
    const day = new Date(date).getDate().toString();
    const month = new Date(date).getMonth();
    const monthString = (month + 1).toString();
    return `${year}-${
      monthString?.length === 1 ? `0${monthString}` : monthString
    }-${day?.length === 1 ? `0${day}` : day}`;
  } catch (e) {
    return "";
  }
};

export const getTreeExpandedIds = (data, subMenuOption, valueOption, maxLevel: number = null): string[] => {
  if (!data?.length) return [];
  const menusIds = [];
  const deepLevel = 1
  try {
    const getMenuItem = (data, level) => {
      if(!!maxLevel && level > maxLevel) return
      data.forEach(item => {
        menusIds.push(item[valueOption].toString());
        if (!!item[subMenuOption]?.length) {
          getMenuItem(item[subMenuOption], level + 1);
        }
      });
    };
    getMenuItem(data, deepLevel);
  } catch (e) {
    return menusIds;
  }
  return menusIds;
};

export const searchTreeItems = (
  data,
  search,
  subMenuOption = "",
  searchOptions = []
) => {
  const searchItems = data => {
    return data
      ?.map(item => {
        const isExist = !!searchOptions?.find(option => {
          return item[option]?.toLowerCase().includes(search);
        });
        if (isExist) {
          return {
            ...item,
            [subMenuOption]: searchItems(item[subMenuOption])
          };
        } else if (!!item[subMenuOption]?.length) {
          const sub = searchItems(item[subMenuOption]);
          if (sub?.length)
            return {
              ...item,
              [subMenuOption]: searchItems(item[subMenuOption])
            };
        }
        return null;
      })
      .filter(item => !!item);
  };
  return searchItems(data);
};

export const getFingerKey = () => {
  let finger = localStorage.getItem(LOCALSTORAGEFIELDS.fingerToken);
  if (!finger || typeof finger !== "string") {
    //@ts-ignore eslint-disable
    const fingerInstance = new Fingerprint();
    /*eslint-enable */
    finger = fingerInstance?.get();
  }
  return finger || "";
};

export const numberWithCommas = (x: number | string): string => {
  try {
    if (x === undefined || x === null) return "";
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  } catch (e) {
    return "";
  }
};