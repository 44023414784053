import {getChosenItemData, getEmployeeRoles} from "../api";
import {Employee, Role, SetIsFetchingType} from "../../../types";

export const getRolesData = async (setIsFetching: SetIsFetchingType = () => {}): Promise<Role[]> => {
  try {
    setIsFetching(true)
    const {groups: roles} = await getEmployeeRoles();
    setIsFetching(false)
    return roles
  } catch (e) {
    setIsFetching(false)
    return []
  }
}

export const getActiveItemData = async (activeItem, setIsFetching): Promise<{users: Employee[], roles: Role[]}> => {
  const defaultRes = {users: [], roles: []}
  if (!activeItem.grant_id && !activeItem.menu_id) return defaultRes
  try {
    setIsFetching(true);
    const res = await getChosenItemData(activeItem);
    setIsFetching(false);
    const {groups, employees} = res
    return {users: employees, roles: groups}
  } catch (e) {
    setIsFetching(false);
    return defaultRes
  }
}