import React from "react";
import { FormHelperText, InputLabel, NativeSelect } from "@material-ui/core";

type CustomNativeSelectType = {
  name?: string,
  placeholder?: string,
  label?: string,
  handleChange?: (e: any) => void,
  value?: string | number,
  error?: boolean,
  errMessage?: string,
  required ?: boolean,
  id?: string,
  options: any[],
  optionValue?: string,
  optionKey?: string,
  optionDisplay?: string,
  hidden?: boolean,
  disabled?: boolean
}
export const CustomNativeSelect = ({
  name,
  placeholder = "Выберите...",
  label,
  handleChange,
  value,
  error = false,
  errMessage,
  required = false,
  id = "",
  options,
  optionValue,
  optionKey,
  optionDisplay,
  hidden = true,
  disabled = false
}: CustomNativeSelectType) => {
  return (
    <>
      <InputLabel
        variant="standard"
        htmlFor="uncontrolled-native"
        error={error}
        required={required}
      >
        {label}
      </InputLabel>
      <NativeSelect
        disabled={disabled}
        fullWidth
        id={id}
        error={error}
        value={value || ""}
        onChange={handleChange}
        inputProps={{
          name: name,
          id: "uncontrolled-native"
        }}
      >
        <option value={""} hidden={hidden}>
          {placeholder}
        </option>
        {options?.map(el => (
          <option value={el[optionValue]} key={el[optionKey]}>
            {el[optionDisplay]}
          </option>
        ))}
      </NativeSelect>
      {error ? (
        <FormHelperText error={error}>{errMessage}</FormHelperText>
      ) : null}
    </>
  );
};
