import React, { useState } from "react";
import { CustomTabPanel, CustomTabs } from "../../../components/blocks/CustomTabs";
import { UserRoles } from "./Roles";
import { UserCities } from "./UserCities";
import { NotFound } from "../../../components/text";
import styles from "../userSettings.module.scss";
import { UserModules } from "./UserModules";
import {Employee} from "../../../types";
import {UserCompanies} from "./UserCompanies";

type UserInfoType = {
  activeUser: Employee
  changeCities: (employee_id: number, city_name: string) => void
}
export const UserInfo = ({ activeUser, changeCities }: UserInfoType) => {
  const [activeTabId, setActiveTabId] = useState(0);

  const handleTabChange = (e, id: number) => {
    setActiveTabId(id);
  };
  return (
    <div>
      <CustomTabs
        disabled={!activeTabId}
        onChange={handleTabChange}
        value={activeTabId}
        tabs={tabs}
      />
      <div className={styles.settings__info__content}>
        {activeUser ? (
          <>
            <h2>{activeUser?.fio}</h2>
            <CustomTabPanel value={activeTabId} index={0}>
              <UserModules activeUserId={activeUser?.employee_id} />
            </CustomTabPanel>
            <CustomTabPanel value={activeTabId} index={1}>
              <UserRoles activeUserId={activeUser?.employee_id} />
            </CustomTabPanel>
            <CustomTabPanel value={activeTabId} index={2}>
              <UserCities
                activeUserId={activeUser?.employee_id}
                changeCities={changeCities}
              />
            </CustomTabPanel>
            <CustomTabPanel value={activeTabId} index={3}>
              <UserCompanies activeUserId={activeUser?.employee_id} />
            </CustomTabPanel>
          </>
        ) : (
          <NotFound>Выберите пользователя</NotFound>
        )}
      </div>
    </div>
  );
};

const tabs = [
  { label: "Модули" },
  { label: "Роли" },
  { label: "Город" },
  { label: "Компании" },
];
