import React, {ComponentType} from "react";
import {Box, Dialog, DialogContent, IconButton} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import Slide from "@material-ui/core/Slide";
import {MODALS_NAMES} from "../../constants";
import {ConfirmModal} from "./modals/ConfirmModal";
import clsx from "clsx";
import {AddGrantItem} from "./modals/AddGrantItem";
import {AddMenuItem} from "./modals/AddMenuItem";
import {AddGroup} from "./modals/AddGroup";
import {GiveTemporarilyAccess} from "./modals/GiveTemporarilyAccess";
import {AddEmployee} from "./modals/AddEmployee";
import {appState, closePopUp} from "../../redux/reducers/appReducer";
import {TextModal} from "./modals/TextModal";
import {DuplicatePermissions} from "./modals/DuplicatePermissions";
import {ModalNamesKeysType, ModalPropsType} from "../../types";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 500, fontSize: "18px", lineHeight: "22px", color: "#1375D1", minWidth: "400px", padding: "0"
  }, header: {
    position: "relative", marginBottom: "5px"
  }, close__btn: {
    position: "absolute", right: "-18px", top: "-20px"
  }, content: {
    background: "#F8FAFB", boxShadow: "0px 5px 20px rgba(102, 102, 102, 0.15)", borderRadius: "4px", padding: "20px"
  }
}));

export default function PopUp() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const appReduxState = useSelector(appState);
  const {popup} = appReduxState
  const {show, type, data} = popup;

  const handleClose = () => {
    dispatch(closePopUp());
  };

  return (<Dialog
    open={show}
    maxWidth="lg"
    //@ts-ignore
    TransitionComponent={Transition}
    onClose={handleClose}
    aria-labelledby="form-dialog-title2"
  >
    <DialogContent
      className={clsx("p-10", classes.content)}
    >
      {!!data?.close && (<Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        className={classes.header}
      >
        <IconButton
          color="primary"
          onClick={handleClose}
          className={classes.close__btn}
        >
          <CloseIcon/>
        </IconButton>
      </Box>)}
      <div
        className="popup-content"
        style={{width: "auto", height: "auto"}}
      >
        {modals.map((item, i) => {
          const Component = item.component;
          return (
            type === item?.type && (
              <Component key={i} data={data} handleClose={handleClose}/>
            )
          );
        })}
      </div>
    </DialogContent>
  </Dialog>);
}

type ModalItemType = {
  component: ComponentType<ModalPropsType>
  type: ModalNamesKeysType
}
const modals: ModalItemType[] = [
  {component: ConfirmModal, type: MODALS_NAMES.confirmModal},
  {component: AddGrantItem, type: MODALS_NAMES.addGrantItem},
  {component: AddMenuItem, type: MODALS_NAMES.addMenuItem},
  {component: AddGroup, type: MODALS_NAMES.addGroup},
  {component: TextModal, type: MODALS_NAMES.textModal},
  {component: GiveTemporarilyAccess, type: MODALS_NAMES.giveTemporarilyAccess},
  {component: AddEmployee, type: MODALS_NAMES.addEmployee},
  {component: DuplicatePermissions, type: MODALS_NAMES.duplicatePermissions},
]
