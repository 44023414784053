import {createSlice} from "@reduxjs/toolkit";
import {Contragent, ModalNamesKeysType, Module, Role, UserDataType} from "../../types";
import {AppStateType} from "../index";
import {ModalTypes} from "../../components/popup/types";

const initialState = {
  init: false,
  isUserAuthenticated: false,
  pageTitle: "",
  popup: {
    show: false,
    type: null,
    data: {}
  },
  dataUser: null,
  isMainMenuOpen: true,
  modules: null,
  moduleContragents: null,
  activeModuleId: null,
  modalLoading: false,
  roles: null
} as appInitialStateType

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setInitialize: (state) => {
      state.init = true
    },
    setAuth: (state, action) => {
      state.isUserAuthenticated = action.payload
    },
    setUserData: (state, action) => {
      state.dataUser = action.payload;
    },
    setModules: (state, action) => {
      state.modules = action.payload;
    },
    closePopUp: <T>(state) => {
      state.popup = {
        show: false,
        type: null,
        data: {}
      };
    },
    showPopUp: {
      reducer: (state, action) => {
        state.popup = action.payload;
      },
      prepare: (type: ModalNamesKeysType, data: ModalTypes<typeof type>, ...props) => {
        return {
          meta: "", error: "",
          payload: {
            show: true,
            type,
            data,
            ...props
          }
        };
      }
    },
    setModuleContragents: (state, action) => {
      state.moduleContragents = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setModalLoading: (state, action) => {
      state.modalLoading = action.payload;
    },
    setMenuState: (state, action) => {
      state.isMainMenuOpen = action.payload;
    },
    setActiveModuleId: (state, action) => {
      state.activeModuleId = action.payload;
    }
  },
});

export const {
  setInitialize,
  setAuth,
  showPopUp,
  closePopUp,
  setUserData,
  setActiveModuleId,
  setMenuState,
  setModuleContragents,
  setModules,
  setRoles,
  setModalLoading
} = appSlice.actions;

export const appState = (state: AppStateType) => state.app
export default appSlice.reducer;

type appInitialStateType = {
  init: boolean,
  isUserAuthenticated: boolean,
  pageTitle: string,
  popup: {
    show: boolean,
    type: ModalNamesKeysType | null,
    data: any
  },
  dataUser: UserDataType | null,
  isMainMenuOpen: boolean,
  modules: Module[] | null,
  moduleContragents: Contragent[] | null,
  activeModuleId: number | null,
  modalLoading: boolean,
  roles: Role[] | null
};
