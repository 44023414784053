import React from "react";
import {Route, Routes as Switch} from "react-router";
import {ManageAccessPage} from "../pages/ManageAccess";
import {ReportAccessList} from "../pages/ManageAccess/ReportAccessList";
import {PAGES, PAGES_VALUES} from "../constants";
import {UserSettingsPage} from "../pages/userSettings";
import {CompareDataOneC} from "../pages/compareDataOneC";

export const Routes = () => {
  return (
    <Switch>
      {routes?.map((item, i) => {
        return (
          <Route key={i} path={item?.path} element={item?.element}>
            {!!item?.nestedRoute && (
              <Route path={item?.nestedRoute} element={item?.nestedElement}/>
            )}
          </Route>
        );
      })}
    </Switch>
  );
};

type RouteType = {
  path: PAGES_VALUES
  element: JSX.Element
  nestedElement?: JSX.Element
  nestedRoute?: PAGES_VALUES
}

const routes: RouteType[] = [
  {path: PAGES.main, element: <ManageAccessPage/>},
  {path: PAGES.permissions, element: <ManageAccessPage/>},
  {path: PAGES.userSettings, element: <UserSettingsPage/>},
  {path: PAGES.compareDataOneC, element: <CompareDataOneC/>},
  {path: PAGES.reportAccessList, element: <ReportAccessList/>},
];

