import React from "react";
import {Box, Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {useSelector} from "react-redux";
import {CButton} from "../../buttons";
import {appState} from "../../../redux/reducers/appReducer";
import {ConfirmModalProps} from "../types";

export const ConfirmModal = ({data, handleClose}: ConfirmModalProps) => {
  const classes = useStyles();
  const {modalLoading} = useSelector(appState);
  const {onSubmit, id, title, type, btnLabel, description} = data;
  return (
    <div className={classes.wrapper}>
      <h1 className={classes.title}>
        {title || "Вы действительно хотите удалить?"}
      </h1>
      {!!description && <p className={classes.desc}>
        {description}
      </p>}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        className={classes.btn_wrap}
      >
        <Button
          className={clsx(classes.btn, classes.cancel)}
          variant="outlined"
          onClick={handleClose}
        >
          Отмена
        </Button>
        <CButton
          loading={modalLoading}
          disabled={modalLoading}
          className={clsx(classes.btn, classes[type || "delete"])}
          onClick={() => onSubmit(id)}
        >
          {btnLabel ||
            (type === "default" ? "Сохранить" : "Удалить")}
        </CButton>
      </Box>
    </div>
  );
};


const useStyles = makeStyles({
  wrapper: {
    maxWidth: 525
  },
  title: {
    maxWidth: "fit-content",
    margin: 0, textAlign: 'center',
    fontSize: "20px",
    lineHeight: "20px",
    marginTop: "5px"
  },
  desc: {
    margin: 0,
    textAlign: 'center',
    fontSize: "16px",
    lineHeight: "16px",
    marginTop: "10px"
  },
  btn: {
    width: 120,
    height: 38,
    padding: 10,
    borderRadius: 4
  },
  btn_wrap: {
    gap: "15px",
    marginTop: "18px"
  },
  delete: {
    color: "#fff",
    backgroundColor: "#FF6262"
  },
  default: {
    color: "#fff",
    backgroundColor: "#1375D1"
  },
  cancel: {
    borderColor: "#1375D1",
    color: "#1375D1"
  }
});