import {setAuth, setInitialize} from "../reducers/appReducer";
import cookiesService from "../../services/cookiesService";
import {handleError} from "../../api/helpers";
import {PAGES} from "../../constants";
import {checkAuth} from "../../api/api_app";

export const initialize = redirect => async dispatch => {
  const cookiesServiceInstance = cookiesService.getService();
  try {
    if (!cookiesServiceInstance.isCredentialsExist()) {
      await redirect(PAGES.login);
      return dispatch(setInitialize());
    }
    await checkAuth();
    dispatch(setAuth(true));
  } catch (e) {
    if (e?.response?.status === 401) {
      return await handleError(e);
    } else {
      await redirect(PAGES.login);
    }
  }
  dispatch(setInitialize())
};


