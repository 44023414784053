import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import styles from "./addMenu.module.scss";
import {InputWarning} from "../../form/warnings";
import {CButton} from "../../buttons";
import {CustomDatePicker} from "../../form/CustomDatePicker";
import {GiveTemporarilyAccessProps} from "./types";

export const GiveTemporarilyAccess = ({ data }: GiveTemporarilyAccessProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();
  const { onSubmit: onAddSubmit } = data;
  const [loading, setLoading] = useState(false);

  const onSubmit = async body => {
    setLoading(true);
    await onAddSubmit(body);
    setLoading(false);
  };

  return (
    <div className={styles.date__wrapper}>
      <h2>Активация временного доступа</h2>
      <form className={styles.menu__form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.menu__form_item}>
          <Controller
            name="date_begin"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomDatePicker
                disablePast
                fullWidth
                placeholder="Дата начала доступа"
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={""}
            rules={{
              required: true
            }}
          />
          {errors.date_begin && (
            <InputWarning>Это поле обязательно</InputWarning>
          )}
        </div>
        <div className={styles.menu__form_item}>
          <Controller
            name="date_end"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomDatePicker
                disablePast
                fullWidth
                placeholder="Дата конца доступа"
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={""}
            rules={{
              required: true
            }}
          />
          {errors.date_end && <InputWarning>Это поле обязательно</InputWarning>}
        </div>
        <div className={styles.menu__form_btn}>
          <CButton loading={loading} disabled={loading}>
            Сохранить
          </CButton>
        </div>
      </form>
    </div>
  );
};
