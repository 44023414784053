import React from "react";
import { PageTitle } from "../../components/pageTitle";
import { UserSettings } from "./UserSettings";

export const UserSettingsPage = () => {
  document.title = "Настройка пользователей";
  return (
    <>
      <PageTitle title={"Введение пользователей"} />
      <UserSettings />
    </>
  );
};
