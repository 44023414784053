import React from "react";
import { PageTitle } from "../../components/pageTitle";
import { ManageAccess } from "./ManageAccess";

export const ManageAccessPage = () => {
  return (
    <div>
      <PageTitle title={"Управление доступом"} />
      <ManageAccess />
    </div>
  );
};
