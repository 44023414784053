import React, {useEffect, useMemo, useState} from "react";
import {useHttp} from "../../../utils/hooks/http.hook";
import {UserInfoContent} from "./Content";
import {getUserRolesData} from "./services";
import {EmployeeRole} from "../../../types";
import {GetEmployeeRolesType} from "./types";
import {CustomSearch} from "../../../components/form/CustomSearch";
import styles from '../userSettings.module.scss'

type UserRolesType = {
  activeUserId: number
}
export const UserRoles = ({activeUserId}: UserRolesType) => {
  const {request, loading} = useHttp();
  const [data, setData] = useState<EmployeeRole[] | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    const getData = async () => {
      const employee_groups = await getUserRolesData(activeUserId, setIsFetching);
      setData(employee_groups);
    };
    getData();
  }, [activeUserId]);

  const handleCheckChange = async group_id => {
    if (loading) return;
    const res = await request<GetEmployeeRolesType>(
      `/core/employees/employee-groups/choose/`,
      "post",
      {group_id, employee_id: activeUserId}
    );
    if (res?.status) setData(res?.employee_groups);
  };

  const handleSearchChange = e => {
    setSearchValue(e.target?.value || '');
  };

  const computedData = useMemo(() => {
    if(!searchValue || !data?.length) return data
    return data?.filter(item => item.group_name.toLowerCase().includes(searchValue.toLowerCase()))
  }, [searchValue, data])
  return (
    <>
      <div className={styles.settings__search}>
        <CustomSearch  handleChange={handleSearchChange} value={searchValue}/>
      </div>
      <UserInfoContent
        data={computedData} noDataText={"У пользователя нет доступа ни к одному модулю"}
        loading={loading}
        isFetching={isFetching}
        handleCheckChange={handleCheckChange}
        idKey={"group_id"}
        labelKey={"group_name"}
      />
    </>
  );
};
