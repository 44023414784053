import React from "react";
import styles from "./WrapperScrollbar.module.scss";

type WrapperScrollbarType = {
  className?: string,
  children?: any,
  type?: string,
  id?: string | null,
  style?: any
}
export const WrapperScrollbar = ({className, children, type, id, style, ...other}: WrapperScrollbarType) => {
  const classNames = [
    styles.wrapper,
    type ? styles[`wrapper_type_${type}`] : "",
    className
  ].join(" ");

  return (
    <div
      style={style}
      id={id || ""}
      className={classNames}
      {...other}
    >
      {children}
    </div>
  );
}
