import React, { useState } from "react";
import ruLocale from "date-fns/locale/ru";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import AdapterDateFns from "@date-io/date-fns";
import { IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { getLocaleDate, getValidDate } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  date: {
    "& .MuiIconButton-root": {
      padding: "2px !important"
    }
  },
  control: {
    "& .MuiFormHelperText-filled": {
      position: "absolute",
      bottom: "-20px"
    }
  }
});

const validFormatDate = (date: string) => {
  try {
    const split = date.split(".");
    if (split.length === 3) {
      const [day, month, year] = split;
      return `${month}.${day}.${year}`;
    }
    return null;
  } catch (e) {
    return null;
  }
};

const getInitialDate = (value: string, alternativeFormat: boolean) => {
  if (!value) return null;
  return alternativeFormat ? value : validFormatDate(value);
};
const isValidDate = d => d instanceof Date

const DEFAULT_WIDTH = 170;

type CustomDatePickerType = {
  name?: string,
  label?: string,
  value?: string,
  className?: string,
  alternativeFormat ?: boolean,
  placeholder?: string,
  absoluteMessage?: boolean,
  shrink?: boolean,
  onChange: (e: any) => void,
  disableFuture?: boolean,
  disablePast?: boolean,
  fullWidth?: boolean,
  width?: number
}
export const CustomDatePicker = ({
                                   name = "",
                                   label = "",
                                   value = "",
                                   className = "",
                                   alternativeFormat = false,
                                   placeholder = "",
                                   absoluteMessage = false,
                                   shrink,
                                   onChange,
                                   disableFuture = false,
                                   disablePast = false,
                                   fullWidth,
                                   width = DEFAULT_WIDTH
                                 }: CustomDatePickerType) => {
  const classes = useStyles();
  const [valueState, setValueState] = useState(
    getInitialDate(value, alternativeFormat)
  );
  const props = {
    name,
    label,
    placeholder,
    disablePast,
    shrink,
    fullWidth,
    sx: {
      width: fullWidth ? "100%" : width
    }
  };
  const handleChange = (e, value) => {
    const event = {
      target: { name, value: getLocaleDate(e) }
    };
    if (!value) {
      if(valueState === null) return
      event.target.value = "";
      setValueState(null);
      return onChange && onChange(event);
    }
    if (!isValidDate(e)) return;
    setValueState(getValidDate(e));
    return onChange && onChange(event);
  };

  const onClear = () => {
    setValueState && setValueState(null);
    return onChange && onChange({ target: { name, value: "" } });
  };

  return (
    <MuiPickersUtilsProvider utils={AdapterDateFns} locale={ruLocale}>
      <KeyboardDatePicker
        name={name}
        format="dd.MM.yyyy"
        className={`${className} ${absoluteMessage ? classes.control : ""}`}
        value={valueState}
        disableToolbar
        disableFuture={disableFuture}
        autoOk
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
        variant={"inline"}
        minDate={new Date("0000-01-01")}
        maxDate={new Date("9999-12-31")}
        fullWidth
        maxDateMessage={"Превышена макс. дата"}
        minDateMessage={"Достигнута мин. дата"}
        invalidDateMessage="Некорректная дата"
        invalidLabel="Некорректная дата"
        inputProps={{
          placeholder: props.placeholder || "Введите дату"
        }}
        onChange={handleChange}
        InputProps={{
          className: classes.date,
          endAdornment: (
            <IconButton onClick={onClear}>
              <ClearIcon />
            </IconButton>
          )
        }}
        InputAdornmentProps={{
          position: "start"
        }}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

