import React from "react";
import {LinearProgress} from "@material-ui/core";

type EmptyOrShowType = {
  loading?: boolean
  show?: boolean,
  children?: any
}
export default function EmptyOrShow({
  loading = true,
  show = true,
  children,
}: EmptyOrShowType) {
  function render() {
    return (
      <>
        {loading ? (
          <LinearProgress color="primary" />
        ) : (
          <>{show ? <>{children}</> : null}</>
        )}
      </>
    );
  }

  return <>{render()}</>;
}
