import React from 'react'
import styles from './compareOneC.module.scss'
import {CustomDatePicker} from "../../components/form/CustomDatePicker";
import {GetReceiptParams} from "./types";
import CustomInput from "../../components/form/CustomInput";
import {CButton} from "../../components/buttons";

type PropsType = {
  handleFiltersChange: (type: keyof GetReceiptParams, n: GetReceiptParams) => void
  getData: () => void
  filters: GetReceiptParams
  loading: boolean
}

export const CompareDataOneCFilters = ({handleFiltersChange, filters, getData, loading}: PropsType) => {

  return <div className={styles.filters}>
    <div className={styles.filters__item}>
      <CustomDatePicker
        fullWidth
        placeholder="Дата от"
        value={filters.date_begin}
        onChange={e =>
          handleFiltersChange("date_begin", e?.target?.value)}
      />
    </div>
    <div className={styles.filters__item}>
      <CustomDatePicker
        fullWidth
        placeholder="Дата до"
        value={filters.date_end}
        onChange={e =>
          handleFiltersChange("date_end", e?.target?.value)}
      />
    </div>
    <div className={styles.filters__item}>
      <CustomInput
        label={'BIN'}
        className={styles.settings__header_search}
        value={filters.provider_bin}
        //@ts-ignore
        onChange={(e: any) =>
          handleFiltersChange("provider_bin", e?.target?.value)
        }
      />
    </div>
    <CButton className={styles.filters__btn} onClick={getData} loading={loading}>
      Поиск
    </CButton>
  </div>
}